import { useEffect } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";

import { setSelectedSiteSection } from "./siteSlice";
import {
  getPendingConfigurationItemsList,
  getSelectedSiteSection
} from "../../redux/selectors/siteSelectors";
import { useAppDispatch } from "../../store";

const SectionMenuTitle = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: 500,
  whiteSpace: "nowrap",
  color: theme.palette.gray.main
}));

export const SectionGroupingMenuTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: 600,
  whiteSpace: "nowrap",
  color: theme.palette.black.main
}));

const MainContainer = styled(Box)(() => ({
  display: "flex",
  padding: "0.5rem 0rem",
  flexDirection: "column",
  alignSelf: "stretch"
}));

const ChildGroupingTitleContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "0.938rem"
}));

const SectionTitleContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{
  extraProps: {
    isSiteSectionSelected: boolean;
  };
}>(({ extraProps }) => ({
  padding: "0.625rem",
  display: "flex",
  flex: 1,
  textAlign: "center",
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: !extraProps.isSiteSectionSelected
    ? "transparent"
    : "#E4ECFF",
  color: !extraProps.isSiteSectionSelected ? "#000" : "#2260D3",
  width: "90%",
  borderRadius: 5,
  "&:hover": {
    backgroundColor: "#E4ECFF",
    color: "#2260D3",
    borderRadius: 5,
    pl: 4,
    width: "90%"
  }
}));

type SiteConfigurationProps = {
  sectionData: SiteConfigurationMenuItem;
  allConfigurationsMenuTypes: SiteConfigurationMenuGroupings;
  childGroupingHeader?: string;
  isInNewSiteCreatingMode: boolean;
};

function SiteConfiguration(props: SiteConfigurationProps) {
  const {
    sectionData,
    allConfigurationsMenuTypes,
    childGroupingHeader,
    isInNewSiteCreatingMode
  } = props;
  const dispatch = useAppDispatch();
  const selectedSiteSection = useSelector(getSelectedSiteSection);
  const pendingConfigurationChangesList = useSelector(
    getPendingConfigurationItemsList
  );
  const { configurationId, children, name: configurationName } = sectionData;
  const isSiteSectionSelected = selectedSiteSection.id === configurationId;

  const setSelectedSectionDetails = (
    configurationId: string,
    configurationName: string
  ): void => {
    dispatch(
      setSelectedSiteSection({
        configurationId,
        configurationName
      })
    );
  };

  const getIsSectionPartOfPendingListOfChanges = (
    configurationId: string
  ): boolean => {
    return !!Object.values(pendingConfigurationChangesList).find(
      (pendingChangeValue) => pendingChangeValue.sectionId === configurationId
    );
  };
  const clearSelectedConfigurationData = () => {
    setSelectedSectionDetails("", "");
  };

  const onClickBtn = () => {
    setSelectedSectionDetails(configurationId, configurationName);
  };

  useEffect(() => {
    return () => {
      clearSelectedConfigurationData();
    };
  }, []);

  const isSectionPartOfPendingListChanges =
    getIsSectionPartOfPendingListOfChanges(configurationId);

  return (
    <MainContainer>
      {childGroupingHeader && (
        <ChildGroupingTitleContainer>
          <SectionGroupingMenuTitle
            data-testid="section-title"
            sx={{
              fontSize: "0.938rem"
            }}
          >
            {childGroupingHeader}
          </SectionGroupingMenuTitle>
          <Divider sx={{ borderBottomWidth: 0.5, margin: "10px 0px" }} />
        </ChildGroupingTitleContainer>
      )}
      <SectionTitleContainer
        extraProps={{
          isSiteSectionSelected
        }}
        onClick={() => onClickBtn()}
      >
        <SectionMenuTitle data-testid="sub-section-title">
          {configurationName}
        </SectionMenuTitle>
        {isSectionPartOfPendingListChanges && (
          <Icon
            sx={{
              marginLeft: "0.5rem"
            }}
          >
            <CheckBoxOutlinedIcon
              height={25}
              width={25}
              sx={{
                color: "#BA8A2C"
              }}
            />
          </Icon>
        )}
      </SectionTitleContainer>
      <Box
        sx={{
          marginLeft: "0.625rem"
        }}
      >
        {children.map(
          (currentChildSection: SiteConfigurationMenuItem, i, arr) => {
            const {
              configurationId,
              configurationTypeId: currentConfigurationGroupingId
            } = currentChildSection;

            const prevChildSection = i > 0 ? arr[i - 1] : null;
            const prevConfigurationGroupingId = prevChildSection
              ? prevChildSection.configurationTypeId
              : "";

            let childGroupingHeader = null;
            if (
              !prevChildSection ||
              (prevChildSection &&
                currentConfigurationGroupingId !== prevConfigurationGroupingId)
            ) {
              childGroupingHeader =
                allConfigurationsMenuTypes?.[currentConfigurationGroupingId];
            }

            return (
              <SiteConfiguration
                key={`child-site-section-${configurationId}`}
                sectionData={currentChildSection}
                allConfigurationsMenuTypes={allConfigurationsMenuTypes}
                childGroupingHeader={childGroupingHeader?.configurationTypeName}
                isInNewSiteCreatingMode={isInNewSiteCreatingMode}
              />
            );
          }
        )}
      </Box>
    </MainContainer>
  );
}

export default SiteConfiguration;
