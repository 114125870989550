import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import ErrorPage from "../pages/ErrorPage";

const ErrorBoundary = (props: Sentry.ErrorBoundaryProps) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => {
        const code = (error as AxiosError<unknown>)?.response?.status;
        const message = (error as AxiosError<unknown>)?.message;
        return <ErrorPage errorMessage={message} errorCode={code} />;
      }}
      {...props}
    />
  );
};

export default ErrorBoundary;
