import { useMemo } from "react";
import { defaultEnvironmentTagNamesListPriority } from "../lib/siteHelpers";

const useValidationRulesPerEnvironment = (
  validationRules: ValidationRuleType[],
  environmentsDetailsById: {
    [envId in string]: EnvironmentsListDetailsType;
  },
  selectedEnvironmentsToEditList?: string[]
): {
  validationRulesPerEnvironment: ConfigurationItemValidationRulesPerEnv;
  allAvailableEnvironmentNames: EnvTagNamesType[];
} => {
  return useMemo(() => {
    const validationRulesPerEnvironment =
      validationRules.reduce<ConfigurationItemValidationRulesPerEnv>(
        (acc, validationRule) => {
          const { environmentId } = validationRule;

          if (selectedEnvironmentsToEditList) {
            if (selectedEnvironmentsToEditList.includes(environmentId)) {
              // eslint-disable-next-line no-param-reassign
              acc[environmentId] = validationRule;
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            acc[environmentId] = validationRule;
          }

          return acc;
        },
        {}
      );

    const allAvailableEnvironmentNames = Object.keys(
      validationRulesPerEnvironment
    )
      .map((envId) => environmentsDetailsById?.[envId]?.environmentName)
      .sort((a, b) => {
        const priority1 =
          defaultEnvironmentTagNamesListPriority[a.toLowerCase()];
        const priority2 =
          defaultEnvironmentTagNamesListPriority[b.toLowerCase()];

        return priority1 - priority2;
      });
    return {
      validationRulesPerEnvironment,
      allAvailableEnvironmentNames
    };
  }, [validationRules, environmentsDetailsById]);
};

export default useValidationRulesPerEnvironment;
