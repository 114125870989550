import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";

const ButtonsContainer = styled(Box)(() => ({
  display: "flex",
  flex: 0.1,
  justifyContent: "space-around",
  width: "100%",
  margin: "auto"
}));

const DuplicateModalActionButtons = (props: {
  onCancelCb: () => void;
  onSubmitCb: () => void;
  submitBtnName: string;
  hasSelectedEnvironmentsListToDuplicateTo?: boolean;
  additionStyling?: unknown;
}) => {
  const {
    onCancelCb,
    onSubmitCb,
    submitBtnName,
    hasSelectedEnvironmentsListToDuplicateTo = false,
    additionStyling = {}
  } = props;
  return (
    <ButtonsContainer
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      sx={{
        ...additionStyling
      }}
    >
      <Button
        sx={{
          minWidth: 180,
          height: 40
        }}
        onClick={onCancelCb}
        color="secondary"
        variant="contained"
      >
        Cancel
      </Button>
      <Button
        sx={{
          minWidth: 180,
          height: 40
        }}
        color="primary"
        variant="contained"
        onClick={onSubmitCb}
        endIcon={
          hasSelectedEnvironmentsListToDuplicateTo ? (
            <CheckIcon
              sx={{
                color: "#fff"
              }}
              data-testid="submit-action-icon"
            />
          ) : undefined
        }
      >
        {submitBtnName}
      </Button>
    </ButtonsContainer>
  );
};

export default DuplicateModalActionButtons;
