import { clientConfigApi } from ".";

export interface ClientType {
  clientId: string;
  name: string;
  salesForceId: string;
  partnerId: string;
  enabled: boolean;
}
export type GetClientsListResponse = ClientType[];

export const clientApi = clientConfigApi.injectEndpoints({
  endpoints: (builder) => ({
    getClientsList: builder.query<GetClientsListResponse, void>({
      query: () => {
        return {
          url: `/api/Clients`,
          method: "GET"
        };
      },
      //@ts-ignore
      transformResponse: (
        response: GetClientsListResponse,
        _: unknown
      ): CustomDrawerContentType[] => {
        return response.map((clientData) => ({
          id: clientData.clientId,
          name: clientData.name,
          salesforceId: clientData.salesForceId
        }));
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ clientId }) => ({
                type: "client" as const,
                id: clientId
              })),
              "client"
            ]
          : ["client"]
    })
  }),
  overrideExisting: false
});

export const { useGetClientsListQuery } = clientApi;
export default clientApi;
