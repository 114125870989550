/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  OnDrawerMenuBtnClickType,
  ToggleSelectedDrawerType,
  navigateToRecentSiteSearch,
  onDrawerOptionBtnClick,
  resetDrawerStructure,
  toggleSelectedDrawer
} from "../drawer/mainCustomDrawerSlice";
import { getPendingChangeConfigurationId } from "../../lib/siteHelpers";
import siteApi from "../../redux/api/site";

type OpenSiteView = "SiteDetails" | "AuditLog" | "HomePage" | "";

export type SubmitDuplicateSiteInformationType = {
  pendingChanges: PendingChangeType[];
  addionalNewSiteInfo: FormattedDuplicateSiteResponse;
  callbackFunc: () => void;
};

export type SiteToDuplicateOrCreateType = {
  selectedEnvironmentsList: string[];
  fromSiteId: string;
  siteConfigurationsByMenuType: SiteConfigurationResponseType;
  requiredSiteConfigurationItems: ConfigurationItemType[];
  generalSiteInformation: DuplicateSiteGeneralInfo;
  requiredSiteFields: PendingChangeType[];
  configurationTypes: DuplicateSiteConfigurationType[];
  allSiteConfigurationsByConfigurationId: AllConfigurationsbyConfigId | null;
  isSiteCreationPending: boolean;
};

export type SiteStateType = {
  selectedSiteSection: {
    name: string;
    id: string;
  };
  currentOpenSiteView: OpenSiteView;
  pendingChangesList: PendingChangesListType;
  siteToDuplicateOrCreate: SiteToDuplicateOrCreateType;
};

export type SetSelectedSiteSectionType = {
  configurationId: string;
  configurationName: string;
};

const initialState: SiteStateType = {
  selectedSiteSection: {
    name: "",
    id: ""
  },
  pendingChangesList: {},
  currentOpenSiteView: "HomePage",
  siteToDuplicateOrCreate: {
    generalSiteInformation: {},
    fromSiteId: "",
    selectedEnvironmentsList: [],
    requiredSiteFields: [],
    siteConfigurationsByMenuType: {
      allConfigurationsMenuTypes: {},
      formattedConfigurationsByMainGroupings: {}
    },
    configurationTypes: [],
    allSiteConfigurationsByConfigurationId: null,
    isSiteCreationPending: false
  }
};

const siteStateSlice = createSlice({
  name: "site",
  initialState: initialState,
  reducers: {
    setSelectedSiteSection(state, action) {
      const { configurationId, configurationName } =
        action.payload as SetSelectedSiteSectionType;

      state.selectedSiteSection.id = configurationId;
      state.selectedSiteSection.name = configurationName;
    },
    updateOpenSiteView(state, action) {
      const siteView = action.payload as OpenSiteView;
      state.currentOpenSiteView = siteView;
    },
    updatePendingChangesList(state, action) {
      const pendingChange = action.payload as PendingChangeType;
      const pendingConfigurationItemId = getPendingChangeConfigurationId(
        pendingChange.configurationItemId,
        pendingChange.sectionId
      );
      state.pendingChangesList[pendingConfigurationItemId] = pendingChange;
    },
    updateSelectedEnvironmentsListToDuplicate(state, action) {
      const { selectedEnvListToDuplicate, fromSiteId } = action.payload as {
        selectedEnvListToDuplicate: string[];
        fromSiteId: string;
      };
      state.siteToDuplicateOrCreate.selectedEnvironmentsList =
        selectedEnvListToDuplicate;
      state.siteToDuplicateOrCreate.fromSiteId = fromSiteId;
    },
    clearSelectedEnvironmentsListToDuplicate(state) {
      state.siteToDuplicateOrCreate.selectedEnvironmentsList = [];
    },
    clearSiteToDuplicateOrCreateInstance(state) {
      state.siteToDuplicateOrCreate = {
        generalSiteInformation: null,
        selectedEnvironmentsList: [],
        requiredSiteFields: [],
        siteConfigurationsByMenuType: {
          allConfigurationsMenuTypes: {},
          formattedConfigurationsByMainGroupings: {}
        },
        configurationTypes: [],
        allSiteConfigurationsByConfigurationId: null,
        isSiteCreationPending: false
      };
    },
    deletePendingChangesListEntry(state, action) {
      const siteId = action.payload as string;
      if (state.pendingChangesList[siteId]) {
        const newPendingChangesList = Object.assign(
          {},
          state.pendingChangesList
        );
        delete newPendingChangesList[siteId];
        state.pendingChangesList = newPendingChangesList;
      }
    },
    submitDuplicateSiteRequiredFields(state, action) {
      const { pendingChanges, addionalNewSiteInfo, callbackFunc } =
        action.payload as SubmitDuplicateSiteInformationType;
      //clear any leftover pending changes from other sites edits
      state.pendingChangesList = {};
      const {
        siteId,
        partnerId,
        clientId,
        salesforceId,
        name: originalSiteName,
        address1,
        address2,
        address3,
        city,
        country,
        siteConfigurationsByMenuType,
        configurationTypes,
        allSiteConfigurationsByConfigurationId
      } = addionalNewSiteInfo;

      const newSiteName =
        pendingChanges.find(
          (el) => el.configurationName.toLowerCase() === "name"
        )?.currentConfigurationItemValues[0]?.configurationValue ||
        originalSiteName;
      const newSiteGeneralInformation: DuplicateSiteGeneralInfo = {
        siteId,
        partnerId,
        clientId,
        salesforceId,
        name: newSiteName,
        displayName: newSiteName,
        enabled: true,
        address1,
        address2,
        address3,
        city,
        country,
        state: addionalNewSiteInfo.state,
        timezone:
          pendingChanges.find(
            (el) => el.configurationName.toLowerCase() === "timezone"
          )?.currentConfigurationItemValues[0]?.configurationValue || null
      };
      //reset selected site section
      state.selectedSiteSection = {
        name: "",
        id: ""
      };
      //prepare temporary duplicate or create site
      state.siteToDuplicateOrCreate = {
        fromSiteId: state.siteToDuplicateOrCreate.fromSiteId,
        selectedEnvironmentsList:
          state.siteToDuplicateOrCreate.selectedEnvironmentsList,
        generalSiteInformation: newSiteGeneralInformation,
        siteConfigurationsByMenuType: siteConfigurationsByMenuType,
        requiredSiteFields: pendingChanges,
        configurationTypes,
        allSiteConfigurationsByConfigurationId,
        isSiteCreationPending: false
      };
      //populate correctly pending changes list
      pendingChanges.forEach((pendingChange: PendingChangeType) => {
        const pendingConfigurationItemId = getPendingChangeConfigurationId(
          pendingChange.configurationItemId,
          pendingChange.sectionId
        );
        state.pendingChangesList[pendingConfigurationItemId] = pendingChange;
      });
      callbackFunc();
    }
  },
  extraReducers: (builder) => {
    builder.addCase(navigateToRecentSiteSearch.type, (state) => {
      state.currentOpenSiteView = "SiteDetails";
    });
    builder.addCase(toggleSelectedDrawer.type, (state, action) => {
      const { isDrawerOpenValue, drawerType } =
        action.payload as ToggleSelectedDrawerType;

      if (drawerType === "client") {
        state.currentOpenSiteView = "HomePage";
      } else if (isDrawerOpenValue || drawerType === "site") {
        state.currentOpenSiteView = "";
      }
    });
    builder.addCase(onDrawerOptionBtnClick.type, (state, action) => {
      const { drawerType, nextDrawerTypeToOpen } =
        action.payload as OnDrawerMenuBtnClickType;

      if (nextDrawerTypeToOpen === "client") {
        state.currentOpenSiteView = "HomePage";
      } else if (drawerType === "site" && !nextDrawerTypeToOpen) {
        state.currentOpenSiteView = "SiteDetails";
        state.pendingChangesList = {};
      } else {
        state.currentOpenSiteView = "";
      }
    });
    builder.addCase(resetDrawerStructure.type, (state) => {
      state.currentOpenSiteView = "HomePage";
      state.pendingChangesList = {};
    });
    builder.addMatcher(
      siteApi.endpoints.postDuplicateOrCreateSite.matchFulfilled,
      (state) => {
        state.pendingChangesList = {};
        //clear duplicate/create site temporary copy
        state.siteToDuplicateOrCreate = {
          generalSiteInformation: {},
          fromSiteId: "",
          selectedEnvironmentsList: [],
          requiredSiteFields: [],
          siteConfigurationsByMenuType: {
            allConfigurationsMenuTypes: {},
            formattedConfigurationsByMainGroupings: {}
          },
          configurationTypes: [],
          allSiteConfigurationsByConfigurationId: null,
          isSiteCreationPending: false
        };
        //clear selected section that contains old data of the duplicate/create temporary site
        state.selectedSiteSection = {
          name: "",
          id: ""
        };
      }
    );
    builder.addMatcher(
      siteApi.endpoints.postDuplicateOrCreateSite.matchPending,
      (state) => {
        state.siteToDuplicateOrCreate.isSiteCreationPending = true;
      }
    );
    builder.addMatcher(
      siteApi.endpoints.postDuplicateOrCreateSite.matchRejected,
      (state) => {
        state.siteToDuplicateOrCreate.isSiteCreationPending = false;
      }
    );
  }
});

export const {
  setSelectedSiteSection,
  updateOpenSiteView,
  updatePendingChangesList,
  deletePendingChangesListEntry,
  updateSelectedEnvironmentsListToDuplicate,
  clearSelectedEnvironmentsListToDuplicate,
  submitDuplicateSiteRequiredFields,
  clearSiteToDuplicateOrCreateInstance
} = siteStateSlice.actions;
export default siteStateSlice.reducer;
