/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-param-reassign */
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { getInitialConfigurationValue } from "../../lib/siteHelpers";
import useValidationRulesPerEnvironment from "../../hooks/useValidationRulesPerEnvironment";
import { generateUUID, isURL } from "../../lib/helpers";

import {
  EnvironmentTagsContainer,
  ItemInputContainer,
  ItemValuesContainer,
  renderEnvTag
} from "./ConfigurationItem";
import ItemInput from "../input/ItemInput";
import { SelectedConfigurationItemType } from "./ConfigurationItems";
import { useSelector } from "react-redux";
import { getSitetoDuplicateOrcreateInfo } from "../../redux/selectors/siteSelectors";
import { useGetEnvironmentsListQuery } from "../../redux/api/environments";

type EnvTagNameListType = {
  [envTagNameType in EnvTagNamesType]: {
    checkedValue: boolean;
    disabled: boolean;
  };
};
type SelectedConfigurationItemValueByEnvType = {
  formattedEnvTagNamesList: EnvTagNamesType[];
  envTagNamesList: EnvTagNameListType;
  value: string | string[] | null | boolean | number;
  key: string;
};

type SelectedConfigItemProps = {
  selectedConfigurationItem: SelectedConfigurationItemType;
  currentConfigurationItem: ConfigurationItemType;
  saveSelectedConfigurationItemChanges: (
    pendingChange: PendingChangeType
  ) => void;
};

const MainContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "flex-start"
}));

const SelectedConfigItem = (props: SelectedConfigItemProps) => {
  const {
    currentConfigurationItem,
    selectedConfigurationItem,
    saveSelectedConfigurationItemChanges
  } = props;
  const siteToDuplicateOrCreate = useSelector(getSitetoDuplicateOrcreateInfo);
  const { dataType, validationRules } = currentConfigurationItem;
  const { originalValues, configurationItemId, configurationName } =
    selectedConfigurationItem;

  const originalEnvironmentsToEditList = originalValues
    .map((el) => el.environmentIds)
    .reduce((acc, el: string[]) => {
      acc.push(...el);
      return acc;
    }, []);
  const selectedEnvironmentsToEditList = siteToDuplicateOrCreate
    .selectedEnvironmentsList.length
    ? siteToDuplicateOrCreate.selectedEnvironmentsList
    : originalEnvironmentsToEditList;

  const { data } = useGetEnvironmentsListQuery(undefined);
  const environmentsData = (data || {}) as EnvironmentsListType;

  const {
    environmentsTagNamesDictionary,
    environmentsDetailsById
  }: EnvironmentsListType = environmentsData;

  const [valuesToDisplay, setNewValues] = useState<
    SelectedConfigurationItemValueByEnvType[]
  >([]);
  const [validation, setValidation] = useState<{
    hasOriginalValues: boolean;
    hasEnvAssignedIncorrectly: boolean;
    arePendingChangesValid: boolean;
    customValidationError: string;
    customValidationErrorPerRow: { [key in string]: string } | null;
  }>({
    hasOriginalValues: false,
    hasEnvAssignedIncorrectly: false,
    arePendingChangesValid: false,
    customValidationError: "",
    customValidationErrorPerRow: null
  });

  const { validationRulesPerEnvironment, allAvailableEnvironmentNames } =
    useValidationRulesPerEnvironment(
      validationRules,
      environmentsDetailsById,
      selectedEnvironmentsToEditList
    );

  const checkIfEnvironmentTagsAreSetCorrectly = () => {
    const selectedConfigItemEnvOccurences = allAvailableEnvironmentNames.reduce(
      (acc, envTagName) => {
        acc[envTagName] = 0;
        return acc;
      },
      {}
    );

    let configItemRowHasNoEnvTagAssigned = false;
    let index = 0;

    while (
      index < valuesToDisplay.length &&
      !configItemRowHasNoEnvTagAssigned
    ) {
      const currentValueToDisplay = valuesToDisplay[index];
      const { formattedEnvTagNamesList } = currentValueToDisplay;

      if (!formattedEnvTagNamesList.length) {
        configItemRowHasNoEnvTagAssigned = true;
      } else {
        formattedEnvTagNamesList.forEach((envTagName) => {
          selectedConfigItemEnvOccurences[envTagName] += 1;
        });
      }
      index++;
    }

    const hasEnvAssignedIncorrectly = !Object.keys(
      selectedConfigItemEnvOccurences
    ).every((envTagName) => selectedConfigItemEnvOccurences[envTagName] === 1);

    if (configItemRowHasNoEnvTagAssigned) {
      setValidation((prevValidation) => ({
        ...prevValidation,
        hasEnvAssignedIncorrectly: true
      }));
    }

    return hasEnvAssignedIncorrectly && !configItemRowHasNoEnvTagAssigned;
  };

  const onAddNewConfigItem = () => {
    const areCurrentChangesValid = checkIfEnvironmentTagsAreSetCorrectly();

    if (!areCurrentChangesValid && valuesToDisplay.length > 0) {
      return;
    }

    const configItemEnvTagName = allAvailableEnvironmentNames.reduce(
      (acc: EnvTagNamesType[], el: EnvTagNamesType) => {
        if (valuesToDisplay.length < 1) {
          acc[el] = {
            checkedValue: true,
            disabled: false
          };
        } else {
          const currentEnvPreviouslySelected = valuesToDisplay.find(
            (newconfigItem) =>
              newconfigItem.formattedEnvTagNamesList.includes(el)
          );
          acc[el] = {
            checkedValue: !currentEnvPreviouslySelected,
            disabled: !!currentEnvPreviouslySelected
          };
        }
        return acc;
      },
      {}
    ) as unknown as {
      [envTagNameType in EnvTagNamesType]: {
        checkedValue: boolean;
        disabled: boolean;
      };
    };

    const configItemEnvTagNameKeys = Object.keys(
      configItemEnvTagName
    ) as EnvTagNamesType[];

    const formattedEnvTagNamesList: EnvTagNamesType[] =
      configItemEnvTagNameKeys.reduce(
        (acc: EnvTagNamesType[], envTagName: EnvTagNamesType) => {
          if (configItemEnvTagName[envTagName].checkedValue) {
            acc.push(envTagName);
          }
          return acc;
        },
        []
      );
    const validationRule = Object.values(validationRulesPerEnvironment)[0];

    setNewValues((prevValuesToDisplay) => [
      ...prevValuesToDisplay,
      {
        formattedEnvTagNamesList,
        envTagNamesList: configItemEnvTagName,
        value: getInitialConfigurationValue(
          dataType,
          validationRule?.validationRules
        ),
        key: `additional-config-item-${generateUUID()}`
      }
    ]);
  };

  useEffect(() => {
    if (!valuesToDisplay.length) onAddNewConfigItem();
  }, [selectedConfigurationItem.configurationItemId]);

  useEffect(() => {
    clearPendingChangesValidation();
  }, [valuesToDisplay]);

  const onCheckboxValueChange = (
    checkBoxValue: boolean,
    selectedConfigItemValue: SelectedConfigurationItemValueByEnvType,
    envTagName: EnvTagNamesType,
    configItemByEnvValuePosition: number
  ) => {
    const newEnvTagNamesList = Object.assign(
      {},
      selectedConfigItemValue.envTagNamesList
    );
    newEnvTagNamesList[envTagName].checkedValue = checkBoxValue;
    const newEnvTagNameslistKeys = Object.keys(
      newEnvTagNamesList
    ) as EnvTagNamesType[];

    const newFormattedEnvTagNamesList = newEnvTagNameslistKeys.filter(
      (tagName: EnvTagNamesType) => newEnvTagNamesList[tagName].checkedValue
    );
    const newSelectedConfigItemValueByEnvType: SelectedConfigurationItemValueByEnvType =
      {
        formattedEnvTagNamesList: newFormattedEnvTagNamesList,
        envTagNamesList: newEnvTagNamesList,
        value: selectedConfigItemValue.value,
        key: `additional-config-item-${generateUUID()}`
      };

    setNewValues(
      valuesToDisplay.map((el, i) => {
        if (i === configItemByEnvValuePosition)
          return newSelectedConfigItemValueByEnvType;
        return el;
      })
    );
  };

  const onRemoveClickBtn = (itemKey: string) => {
    if (valuesToDisplay.length === 1) return;

    const newValuesToDisplay = valuesToDisplay.filter(
      (el) => el.key !== itemKey
    );
    setNewValues(newValuesToDisplay);
  };

  const handleInputChange = (
    newValue: string | string[] | boolean,
    currentValuesToDisplayIndex: number,
    originalSelectedConfigItemValue: SelectedConfigurationItemValueByEnvType
  ) => {
    const newConfigItem: SelectedConfigurationItemValueByEnvType = {
      ...originalSelectedConfigItemValue,
      value: newValue
    };
    const newArr = [...valuesToDisplay];
    newArr[currentValuesToDisplayIndex] = newConfigItem;
    setNewValues(newArr);
  };

  const validateConfigurationItemsToDisplay = () => {
    const selectedConfigItemEnvOccurences = allAvailableEnvironmentNames.reduce(
      (acc, envTagName) => {
        acc[envTagName] = 0;
        return acc;
      },
      {}
    );

    const hasValidConfigItemValues = valuesToDisplay.reduce<{
      sameOriginalValuesMap: { [key in string]: boolean };
      customValidation: {
        errorsValidationMap: {
          [index in string]: string;
        };
      };
    }>(
      (acc, el, i, arr) => {
        const { value, formattedEnvTagNamesList } = el;
        let isFieldRequired = false;
        const selectedEnvIdsList: string[] = [];

        acc.sameOriginalValuesMap[el.key] = false;

        // check if the env are correctly distributed by keeping track of the envs occurences
        formattedEnvTagNamesList.forEach((envTagName) => {
          const envId = environmentsTagNamesDictionary?.[envTagName] || "";
          const validationRule = validationRulesPerEnvironment[envId];
          isFieldRequired = validationRule?.isRequired || false;
          selectedEnvIdsList.push(envId);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          selectedConfigItemEnvOccurences[envTagName] += 1;
        });

        // if there is similar values per env distribution check if the input value(s) are the same as the original one(s)
        if (
          arr.length === currentConfigurationItem.configurationValues.length
        ) {
          const selectedEnvFormattedList = selectedEnvIdsList.sort().join(",");

          currentConfigurationItem.configurationValues.forEach(
            (originalConfigItem) => {
              const { configurationValue, environmentIds } = originalConfigItem;
              let originalConfigurationValue = configurationValue;
              let currentConfigurationValue = value;

              if (typeof currentConfigurationValue === "string") {
                currentConfigurationValue = currentConfigurationValue.trim();
              } else if (
                typeof currentConfigurationValue === "object" &&
                typeof originalConfigurationValue === "object" &&
                originalConfigurationValue &&
                currentConfigurationValue
              ) {
                const originalMultilineFormattedValues = (
                  originalConfigurationValue || []
                )
                  .slice()
                  .sort((a: string, b: string) => a.localeCompare(b))
                  .join(",");

                const currentMultiLineFormattedValues =
                  currentConfigurationValue
                    .sort((a: string, b: string) => a.localeCompare(b))
                    .join(",");

                originalConfigurationValue = originalMultilineFormattedValues;
                currentConfigurationValue = currentMultiLineFormattedValues;
              } else if (typeof currentConfigurationValue === "boolean") {
                currentConfigurationValue = String(currentConfigurationValue);
                originalConfigurationValue = String(originalConfigurationValue);
              }

              if (originalConfigurationValue === currentConfigurationValue) {
                const originalEnvFormattedList = environmentIds
                  .slice()
                  .sort()
                  .join(",");

                if (selectedEnvFormattedList === originalEnvFormattedList) {
                  acc.sameOriginalValuesMap[el.key] = true;
                }
              }
            }
          );
        }

        // url validation check
        if (
          typeof value !== "boolean" &&
          ((typeof value === "string" && !value) ||
            (typeof value === "object" && !value?.length))
        ) {
          if (isFieldRequired || arr.length === 1)
            acc.customValidation.errorsValidationMap[el.key] =
              "No empty values allowed.";
        } else if (value && typeof value === "string") {
          if (dataType === "url") {
            if (!isURL(value)) {
              acc.customValidation.errorsValidationMap[el.key] =
                "Must be a valid URL value.";
            }
          }

          // uniquePerEnvironment validation check (mostly applies for url data type)
          if (currentConfigurationItem.uniquePerEnvironment) {
            const nextSelectedconfigItem = i < arr.length ? arr[i + 1] : null;
            if (
              nextSelectedconfigItem?.value &&
              value === nextSelectedconfigItem?.value
            ) {
              acc.customValidation.errorsValidationMap[el.key] =
                "Values must be unique per environment.";
              acc.customValidation.errorsValidationMap[
                nextSelectedconfigItem.key
              ] = "Values must be unique per environment.";
            }
          }
        }

        return acc;
      },
      {
        sameOriginalValuesMap: {},
        customValidation: {
          errorsValidationMap: {}
        }
      }
    );
    const hasEnvAssignedIncorrectly = !Object.keys(
      selectedConfigItemEnvOccurences
    ).every((envTagName) => selectedConfigItemEnvOccurences[envTagName] === 1);

    const hasOriginalValues = Object.values(
      hasValidConfigItemValues.sameOriginalValuesMap
    ).every((sameValueAsOriginal) => !!sameValueAsOriginal);

    return {
      ...hasValidConfigItemValues,
      hasEnvAssignedIncorrectly,
      hasOriginalValues
    };
  };

  const checkPendingChangesToSave = () => {
    const { hasEnvAssignedIncorrectly, hasOriginalValues, customValidation } =
      validateConfigurationItemsToDisplay();
    const hasCustomErrors = !!Object.keys(
      customValidation.errorsValidationMap || {}
    ).length;

    setValidation({
      hasOriginalValues,
      hasEnvAssignedIncorrectly,
      arePendingChangesValid:
        !hasOriginalValues && !hasEnvAssignedIncorrectly && !hasCustomErrors,
      customValidationError: "",
      customValidationErrorPerRow: customValidation.errorsValidationMap
    });

    return {
      hasEnvAssignedIncorrectly,
      hasOriginalValues,
      arePendingChangesValid:
        !hasOriginalValues && !hasEnvAssignedIncorrectly && !hasCustomErrors
    };
  };

  const clearPendingChangesValidation = () =>
    setValidation({
      hasOriginalValues: false,
      hasEnvAssignedIncorrectly: false,
      arePendingChangesValid: false,
      customValidationError: "",
      customValidationErrorPerRow: null
    });

  const handleSaveConfigItemChanges = () => {
    const { arePendingChangesValid } = checkPendingChangesToSave();
    if (!arePendingChangesValid) return;

    const currentConfigurationItemValues = valuesToDisplay.reduce<
      ConfigurationValue[]
    >((acc, configItem) => {
      const { formattedEnvTagNamesList, value } = configItem;

      const environmentIds: string[] = formattedEnvTagNamesList.map(
        (environmentName) => environmentsTagNamesDictionary?.[environmentName]
      );
      const newConfigItem: ConfigurationValue = {
        environmentIds,
        configurationValue: value
      };

      acc.push(newConfigItem);
      return acc;
    }, []);

    const pendingChange: PendingChangeType = {
      originalConfigurationItemValues: originalValues,
      currentConfigurationItemValues: currentConfigurationItemValues,
      configurationName: configurationName,
      integrationName: currentConfigurationItem.integrationName,
      description: currentConfigurationItem.description,
      configurationItemId,
      configurationId: currentConfigurationItem.configurationId,
      configurationTypeId: currentConfigurationItem.configurationTypeId,
      uniquePerEnvironment: currentConfigurationItem.uniquePerEnvironment,
      sectionName: "",
      sectionId: "",
      dataType,
      validationRules
    };

    saveSelectedConfigurationItemChanges(pendingChange);
  };

  const renderErrorInformation = (): string => {
    const {
      hasEnvAssignedIncorrectly,
      hasOriginalValues,
      customValidationError
    } = validation;

    if (hasEnvAssignedIncorrectly)
      return "Environment(s) not assigned correctly.";
    else if (hasOriginalValues)
      return "No same values as the original one(s) allowed.";
    else if (customValidationError) return customValidationError;

    return "";
  };

  const setCustomValidationError = (customValidationError: string) => {
    setValidation((prevValidation) => ({
      ...prevValidation,
      customValidationError
    }));
  };

  return (
    <MainContainer
      data-testid={`selected-config-item-${currentConfigurationItem.configurationItemId}`}
      key={`selected-cofig-item-${selectedConfigurationItem.configurationItemId}`}
    >
      <Divider sx={{ margin: "20px" }} />
      <ItemValuesContainer>
        {valuesToDisplay.map((item, index, arr) => {
          const { value, envTagNamesList, key } = item;

          const isLastElement = index === arr.length - 1;
          const validationRule = Object.values(
            validationRulesPerEnvironment
          )[0];
          const customValidationErrorMessage =
            validation.customValidationErrorPerRow?.[key];

          return (
            <Box
              key={key}
              sx={{
                display: "flex",
                flexDirection: "column"
              }}
              data-testid={`selected-config-item-display-value-${currentConfigurationItem.configurationItemId}`}
            >
              <ItemInputContainer>
                <Box
                  sx={{
                    height: 30,
                    width: 30
                  }}
                >
                  {
                    <IconButton
                      aria-label="arrow-circle-btn"
                      data-testid="enter-btn"
                      disableRipple
                      onClick={() => onRemoveClickBtn(key)}
                    >
                      {arr.length > 1 && index > 0 ? (
                        <CloseIcon
                          sx={{
                            height: 28,
                            width: 28
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </IconButton>
                  }
                </Box>
                <ItemInput
                  configurationValue={value}
                  dataType={dataType}
                  required={validationRule?.isRequired}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  validationRules={validationRule?.validationRules}
                  onInputChange={(newConfigItemValue) =>
                    handleInputChange(newConfigItemValue, index, item)
                  }
                  setValidationError={setCustomValidationError}
                />
                <EnvironmentTagsContainer data-testid="environments-checkbox-list">
                  {allAvailableEnvironmentNames.map((envName) => {
                    const currentCheckBoxValue = envTagNamesList[envName];
                    const shouldDisableCheckBoxForEnvTag =
                      (isLastElement && currentCheckBoxValue?.disabled) ||
                      !isLastElement;
                    return (
                      <Box
                        key={`edit-env-values-${envName || generateUUID()}`}
                        sx={{
                          display: "flex",
                          alignItems: "center"
                        }}
                        data-testid={`edit-env-values-${envName}`}
                      >
                        <Checkbox
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            onCheckboxValueChange(
                              e.target.checked,
                              item,
                              envName,
                              index
                            );
                          }}
                          checked={currentCheckBoxValue?.checkedValue}
                          color="secondary"
                          disabled={shouldDisableCheckBoxForEnvTag}
                        />

                        {renderEnvTag(envName)}
                      </Box>
                    );
                  })}
                </EnvironmentTagsContainer>
              </ItemInputContainer>
              {!!customValidationErrorMessage && (
                <Box data-testid="selected-item-validation-error">
                  <Typography color="error.main" textAlign="center">
                    {customValidationErrorMessage}
                  </Typography>
                </Box>
              )}
            </Box>
          );
        })}
      </ItemValuesContainer>
      {!validation.arePendingChangesValid && (
        <Box
          sx={{ marginTop: "5px" }}
          data-testid="selected-item-overall-validation-error"
        >
          <Typography color="error.main" textAlign="center">
            {renderErrorInformation()}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: !validation.arePendingChangesValid ? "10px" : "20px"
        }}
      >
        <Button
          sx={{
            minWidth: 180,
            height: 35
          }}
          color="secondary"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={onAddNewConfigItem}
        >
          Add other value
        </Button>
        <Button
          sx={{
            minWidth: 180,
            height: 35
          }}
          color="primary"
          variant="contained"
          startIcon={
            <CheckIcon
              sx={{
                color: "#fff"
              }}
            />
          }
          onClick={handleSaveConfigItemChanges}
        >
          Save changes
        </Button>
      </Box>
      <Divider sx={{ margin: "20px" }} />
    </MainContainer>
  );
};

export default SelectedConfigItem;
