import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { mainTheme } from "./lib/theme";
import Home from "./pages/Home";
import ErrorBoundary from "./components/ErrorBoundary";
import Login from "./pages/Login";
import AuthCallback from "./components/login/AuthCallback";
import PrivateRoute from "./PrivateRoute";
import { useSelector } from "react-redux";
import { checkUserLoggedInStatus } from "./redux/selectors/userSelectors";
import { MessagePopper } from "./components/messagePopper/MessagePopper";
import { useGetEnvironmentsListQuery } from "./redux/api/environments";

function App() {
  const isUserLoggedIn = useSelector(checkUserLoggedInStatus);
  const redirectPath = isUserLoggedIn ? "/" : "/login";
  useGetEnvironmentsListQuery(undefined, {
    skip: !isUserLoggedIn
  });
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mainTheme}>
        <BrowserRouter>
          <ErrorBoundary>
            <div
              id="App"
              style={{
                minHeight: "100vh",
                overflow: "auto",
                margin: 0
              }}
            >
              <Routes>
                <Route
                  path="/login"
                  element={
                    isUserLoggedIn ? (
                      <Navigate replace to={redirectPath} />
                    ) : (
                      <Login />
                    )
                  }
                />
                <Route path="/callback" element={<AuthCallback />} />
                <Route element={<PrivateRoute />}>
                  <Route path="/" element={<Home />} />
                </Route>

                <Route
                  path="*"
                  element={<Navigate replace to={redirectPath} />}
                />
              </Routes>
              <MessagePopper />
            </div>
          </ErrorBoundary>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
