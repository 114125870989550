import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export const getAllDrawerState = (state: RootState) =>
  state.mainCustomDrawer.drawerState;

export const getCurrentOpenDrawerType = (state: RootState) =>
  state.mainCustomDrawer.currentSelectedOpenDrawerType;

export const getSelectedDrawersData = (state: RootState) =>
  state.mainCustomDrawer.selectedDrawersData;

export const getAvailableDrawers = (state: RootState) => {
  const drawers = state.mainCustomDrawer.drawerState;
  const availableDrawersToDisplay = {} as DrawerState;

  for (const [drawerType, drawerOption] of Object.entries(drawers)) {
    if (drawerOption.shouldDisplay) {
      //@ts-ignore
      availableDrawersToDisplay[drawerType] = drawerOption;
    }
  }
  return availableDrawersToDisplay;
};
export const getCurrentDrawerStateProperties = (drawerType: DrawerType) =>
  createSelector([getAvailableDrawers], (drawerState: DrawerState) => {
    const availableDrawersToDisplayByType = Object.keys(drawerState);
    const currentSelectedDrawerPosition =
      //@ts-ignore
      availableDrawersToDisplayByType.findIndex((el) => el === drawerType);

    const prevIndex = currentSelectedDrawerPosition - 1;
    const nextIndex = currentSelectedDrawerPosition + 1;

    const previousDrawer =
      (prevIndex >= 0 && availableDrawersToDisplayByType[prevIndex]) || null;

    const nextDrawer =
      (nextIndex < availableDrawersToDisplayByType.length &&
        availableDrawersToDisplayByType[nextIndex]) ||
      null;

    return {
      ...drawerState[drawerType],
      previousDrawer,
      nextDrawer
    };
  });

export const getRootDrawer = () =>
  createSelector([getAvailableDrawers], (drawers: DrawerState) => {
    const rootDrawerTypeName = Object.keys(drawers)[0] as DrawerType;
    return {
      drawerName: rootDrawerTypeName,
      ...drawers[rootDrawerTypeName]
    };
  });
