import { useSelector } from "react-redux";

import { SelectedSingleDrawerDataType } from "./mainCustomDrawerSlice";
import CustomDrawerContent from "./CustomDrawerContent";
import { useGetClientsListByPartnerQuery } from "../../redux/api/partner";
import { getSelectedDrawersData } from "../../redux/selectors/drawerSelectors";
import { useGetClientsListQuery } from "../../redux/api/client";

type ClientDrawerContentProps = {
  onDrawerMenuBtnClick: (
    selectedDrawerOption: SelectedSingleDrawerDataType,
    parentDetails?: SelectedSingleDrawerDataType | null
  ) => void;
  isDrawerDisabled?: boolean;
  drawerType: DrawerType;
};

function ClientDrawerContent(props: ClientDrawerContentProps) {
  const { isDrawerDisabled } = props;
  const selectedPartnerDrawerData = useSelector(getSelectedDrawersData).partner;
  const partnerId = selectedPartnerDrawerData?.id || "";
  const clientsListByPartnerResponse = useGetClientsListByPartnerQuery(
    {
      partnerId
    },
    { skip: !partnerId, refetchOnMountOrArgChange: true }
  );
  const completeClientsListResponse = useGetClientsListQuery(undefined, {
    skip: !!partnerId || isDrawerDisabled,
    refetchOnMountOrArgChange: true
  });

  const clientsList =
    (partnerId
      ? clientsListByPartnerResponse.data
      : completeClientsListResponse.data) || [];

  const isDataLoading = partnerId
    ? clientsListByPartnerResponse.isLoading
    : completeClientsListResponse.isLoading;

  return (
    <CustomDrawerContent
      {...props}
      isDataLoading={isDataLoading}
      drawerContentData={clientsList as unknown as CustomDrawerContentType[]}
    />
  );
}

export default ClientDrawerContent;
