import Header from "../site/Header";
import { MainContainer } from "../site/MainSiteContainer";
import AuditLogTable from "./AuditLogTable";

const getAuditLogsDummyData = () =>
  Array.from({ length: 15 }, (_el, i) => ({
    configName: `Configuration${i + 1}`,
    originalValue: `old-test-value-${i + 1}`,
    currentValue: `new-test-value-${i + 1}`,
    environments: i % 2 === 0 ? ["Staging", "QA"] : ["Staging", "Production"],
    date: Date.now(),
    user: `user${i + 1}@autostoresytems.com`,
    configGroupName: "N/A"
  }));

const AuditLog = () => {
  //temporary dummy data
  const auditLogs = getAuditLogsDummyData();
  const columnHeaderName = [
    "Configuration Item Name",
    "Original Value",
    "Current Value",
    "Environment(s)",
    "Date",
    "User",
    "Configuration Grouping"
  ];

  return (
    <MainContainer sx={{ overflow: "none" }}>
      <Header />
      <AuditLogTable
        columnHeaderNames={columnHeaderName}
        rowsData={auditLogs}
      />
    </MainContainer>
  );
};

export default AuditLog;
