import { RootState } from "../../store";

export const getSelectedSiteSection = (state: RootState) =>
  state.site.selectedSiteSection;

export const getCurrentOpenSiteView = (state: RootState) =>
  state.site.currentOpenSiteView;

export const getPendingConfigurationItemsList = (state: RootState) =>
  state.site.pendingChangesList;

export const getSitetoDuplicateOrcreateInfo = (state: RootState) =>
  state.site.siteToDuplicateOrCreate;
