import Box from "@mui/material/Box";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";

type MultiselectInputPropsType = {
  value: string[];
  multiselectValues: string[];
  readOnly?: boolean;
  isDisabled?: boolean;
  onInputChange?: (value: string | string[]) => void;
  isPartOfPendingChangesList?: boolean;
  inputWidth?: number;
};

const MultiselectInput = (props: MultiselectInputPropsType) => {
  const {
    value,
    multiselectValues,
    isDisabled = false,
    readOnly,
    onInputChange,
    isPartOfPendingChangesList,
    inputWidth
  } = props;

  const handleDelete = (itemValueToDelete: string) => {
    if (readOnly) return;
    const newMultiSelectValue = value.filter((el) => el !== itemValueToDelete);

    if (onInputChange) onInputChange(newMultiSelectValue);
  };

  const handleChange = (e: SelectChangeEvent<string[]>) => {
    if (readOnly) return;
    const selectedConfigItem = e.target.value as string[];
    if (onInputChange) onInputChange(selectedConfigItem);
  };
  const warningColor = isPartOfPendingChangesList ? "warning.dark" : "#E2E2E2";

  return (
    <Select
      disabled={isDisabled || readOnly}
      multiple
      value={value}
      onChange={handleChange}
      input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
      renderValue={(selected: string[]) => (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          {selected.map((value: string, i) => (
            <Chip
              key={`multiselect-chip-${value}-${i}`}
              data-testid={`multiselect-chip-btn`}
              label={value}
              variant="outlined"
              onDelete={() =>
                isDisabled || readOnly ? undefined : handleDelete(value)
              }
              onMouseDown={(event) => {
                event.stopPropagation();
              }}
              disabled={isDisabled}
              sx={{
                backgroundColor: "#ffff"
              }}
            />
          ))}
        </Box>
      )}
      sx={{
        backgroundColor: isDisabled ? "gray.light" : "white.main",
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: "rgba(0, 0, 0, 0.87)"
        },
        "&.MuiOutlinedInput-root": {
          "& .MuiOutlinedInput-notchedOutline": {
            top: 0,
            borderWidth: isPartOfPendingChangesList ? "3px" : "1px",
            borderRadius: isPartOfPendingChangesList ? "10px" : "inherit"
          },
          "&.Mui-disabled fieldset": {
            borderColor: warningColor
          }
        },
        "& legend": { display: "none" },
        fontSize: 15,
        width: inputWidth || 250,
        minHeight: 50
      }}
      inputProps={{
        style: {
          padding: 10
        }
      }}
    >
      {multiselectValues.map((value: string, i) => (
        <MenuItem key={`multiselect-value-${value}-${i}`} value={value}>
          {value}
        </MenuItem>
      ))}
    </Select>
  );
};
export default MultiselectInput;
