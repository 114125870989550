import { clientConfigApi } from ".";
import { GetClientsListResponse } from "./client";

export type GetPartnersListResponse = { partnerId: string; name: string }[];

export const partnerApi = clientConfigApi.injectEndpoints({
  endpoints: (builder) => ({
    getPartnersList: builder.query<GetPartnersListResponse, void>({
      query: () => {
        return {
          url: "/api/Partners",
          method: "GET"
        };
      },
      //@ts-ignore
      transformResponse: (
        response: GetPartnersListResponse,
        _: unknown
      ): CustomDrawerContentType[] => {
        return response.map((partnerData) => ({
          id: partnerData.partnerId,
          name: partnerData.name,
          salesforceId: ""
        }));
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ partnerId }) => ({
                type: "partner" as const,
                id: partnerId
              })),
              "partner"
            ]
          : ["partner"]
    }),
    getClientsListByPartner: builder.query<
      GetClientsListResponse,
      { partnerId: string }
    >({
      query: (data) => {
        const { partnerId } = data;
        return {
          url: `/api/Clients/${partnerId}`,
          method: "GET"
        };
      },
      //@ts-ignore
      transformResponse: (
        response: GetClientsListResponse,
        _: unknown
      ): CustomDrawerContentType[] => {
        return response.map((clientData) => ({
          id: clientData.clientId,
          name: clientData.name,
          salesforceId: clientData.salesForceId
        }));
      },
      providesTags: ["client"]
    })
  }),
  overrideExisting: false
});

export const { useGetPartnersListQuery, useGetClientsListByPartnerQuery } =
  partnerApi;

export default partnerApi;
