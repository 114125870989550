import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Link from "@mui/material/Link";
import { useSelector } from "react-redux";
import { getRecentSiteSearches } from "../../redux/selectors/homepageSelectors";
import { navigateToRecentSiteSearch } from "../drawer/mainCustomDrawerSlice";
import { RecentSearchType } from "./homepageSlice";
import { useAppDispatch } from "../../store";

const ViewRecentSearchesTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 21,
  color: theme.palette.gray.main
}));

const MainContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "baseline",
  flex: "auto"
}));

const RecentSearchLinkContainer = styled(Box)(() => ({
  display: "flex",
  padding: "8px 10px 8px 6px"
}));

const RecentSearchLink = styled(Link)(() => ({
  alignItems: "center",
  marginBottom: "10px",
  fontSize: 18,
  fontWeight: 300
}));

const RecentSearches = () => {
  const dispatch = useAppDispatch();
  const recentSiteSearches = useSelector(getRecentSiteSearches);

  const onRecentSiteSearchClick = (recentSelectedSite: RecentSearchType) => {
    dispatch(navigateToRecentSiteSearch(recentSelectedSite));
  };

  return (
    <MainContainer data-testid="recent-searches-container">
      <ViewRecentSearchesTitle data-testid="recent-searches-title">
        Select recently viewed project:
      </ViewRecentSearchesTitle>
      {recentSiteSearches.length ? (
        recentSiteSearches.map((search, index) => {
          const { siteName, clientName, siteId, clientId } = search;
          return (
            <RecentSearchLinkContainer
              key={`${clientName}-${siteName}-${index}`}
              data-testid="recent-search"
            >
              <RecentSearchLink
                component="button"
                onClick={() =>
                  onRecentSiteSearchClick({
                    siteId,
                    siteName,
                    clientName,
                    clientId
                  })
                }
              >{`${clientName} > ${siteName}`}</RecentSearchLink>
            </RecentSearchLinkContainer>
          );
        })
      ) : (
        <Typography fontWeight={300}>
          No recent viewed projects found.
        </Typography>
      )}
    </MainContainer>
  );
};

export default RecentSearches;
