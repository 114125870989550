/* eslint-disable no-param-reassign */
import { clientConfigApi } from ".";
import { defaultEnvironmentTagNamesListPriority } from "../../lib/siteHelpers";

export type GetEnvironmentsList = EnvironmentsListDetailsType[];

export const environmentsApi = clientConfigApi.injectEndpoints({
  endpoints: (builder) => ({
    getEnvironmentsList: builder.query<GetEnvironmentsList, void>({
      query: () => {
        return {
          url: "/api/Environments",
          method: "GET"
        };
      },
      //@ts-ignore
      transformResponse: (
        response: GetEnvironmentsList
      ): EnvironmentsListType => {
        const sortedResponse = response.sort((a, b) => {
          const priority1 =
            defaultEnvironmentTagNamesListPriority[
              a.environmentName.toLowerCase()
            ];
          const priority2 =
            defaultEnvironmentTagNamesListPriority[
              b.environmentName.toLowerCase()
            ];

          return priority1 - priority2;
        });

        const environmentsTagNamesDictionary = sortedResponse.reduce<{
          [envTagName in EnvTagNamesType]: string;
        }>((acc, el) => {
          acc[el.environmentName as EnvTagNamesType] = el.environmentId;
          return acc;
        }, {});

        const environmentsTagNamesList = sortedResponse.map(
          (el) => el.environmentName
        );

        const environmentsDetailsById = sortedResponse.reduce<{
          [envId in string]: EnvironmentsListDetailsType;
        }>((acc, el: EnvironmentsListDetailsType) => {
          acc[el.environmentId] = el;
          return acc;
        }, {});

        return {
          environmentsTagNamesDictionary,
          environmentsTagNamesList,
          environmentsDetailsById
        };
      },
      providesTags: ["environments"]
    })
  }),
  overrideExisting: false
});

export const { useGetEnvironmentsListQuery } = environmentsApi;
export default environmentsApi;
