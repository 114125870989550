import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  DescriptionTooltip,
  EnvironmentTag,
  EnvironmentTagsContainer,
  ItemInputContainer,
  renderEnvTag
} from "../site/ConfigurationItem";
import ItemInput from "../input/ItemInput";
import { DuplicateSiteRequiredType } from "./DuplicateSiteContent";
import usePrevious from "../../hooks/usePrevious";
import { desktopWidth, tabletWidth } from "../../lib/theme";
import useValidationRulesPerEnvironment from "../../hooks/useValidationRulesPerEnvironment";
import { generateUUID } from "../../lib/helpers";
import { useGetEnvironmentsListQuery } from "../../redux/api/environments";

type RequieredSiteFieldsProps = {
  configurationItem: DuplicateSiteRequiredType;
  updateConfigItemCurrentValue: (
    configurationItemId: string,
    currentValue: string | string[] | boolean | number | null,
    currentValueEnvId?: string
  ) => void;
  validationErrors: {
    currentValueErrorMessage: string;
    currentValuesPerEnvValidationErrors: {
      [envId in string]: string;
    };
  };
  setValidationError: (
    errorMessage: string,
    configItemId: string,
    uniquePerEnvironment?: boolean,
    currentValueEnvId?: string
  ) => void;
  selectedEnvironmentsList: string[];
};

const MainContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  alignSelf: "flex-end"
}));

export const ConfigurationItemName = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{
  extraProps: {
    uniquePerEnvironment?: boolean;
  };
}>(({ extraProps, theme }) => ({
  fontSize: "1rem",
  fontWeight: "500",
  color: theme.palette.gray.main,
  position: "relative",
  alignSelf: extraProps?.uniquePerEnvironment ? "flex-start" : "center",
  top: extraProps?.uniquePerEnvironment ? "20px" : "0px"
}));

const RequieredSiteFields = (props: RequieredSiteFieldsProps) => {
  const {
    configurationItem,
    updateConfigItemCurrentValue,
    validationErrors,
    setValidationError,
    selectedEnvironmentsList
  } = props;
  const {
    dataType,
    name: configurationName,
    configurationItemId,
    currentValue,
    uniquePerEnvironment,
    currentValuesPerEnv,
    validationRules,
    description
  } = configurationItem;
  const previousUniquerPerEnvValue = usePrevious(currentValuesPerEnv);

  const { data } = useGetEnvironmentsListQuery(undefined);
  const environmentsData = (data || {}) as EnvironmentsListType;

  const { environmentsDetailsById = {} }: EnvironmentsListType =
    environmentsData;

  React.useEffect(() => {
    if (!uniquePerEnvironment) setValidationError("", configurationItemId);
  }, [currentValue]);

  React.useEffect(() => {
    if (uniquePerEnvironment && currentValuesPerEnv) {
      if (
        Object.values(
          validationErrors.currentValuesPerEnvValidationErrors
        )[0] === "URL values must be unique per environment."
      ) {
        Object.keys(validationErrors.currentValuesPerEnvValidationErrors).map(
          (envId) => {
            setValidationError(
              "",
              configurationItemId,
              uniquePerEnvironment,
              envId
            );
          }
        );
      } else {
        Object.keys(currentValuesPerEnv).forEach((envId) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const prevValueForEnv = previousUniquerPerEnvValue?.[envId] || "";
          const currentValueForEnv = currentValuesPerEnv?.[envId] || "";
          if (prevValueForEnv !== currentValueForEnv) {
            setValidationError(
              "",
              configurationItemId,
              uniquePerEnvironment,
              envId
            );
          }
        });
      }
    }
  }, [currentValuesPerEnv]);

  const { validationRulesPerEnvironment } = useValidationRulesPerEnvironment(
    validationRules,
    environmentsDetailsById
  );

  const isDesktopWidth = useMediaQuery(desktopWidth);
  const isTabletWidth = useMediaQuery(tabletWidth);
  const inputWidth = isTabletWidth ? 200 : isDesktopWidth ? 250 : 350;

  return (
    <MainContainer data-testid="duplicate-modal-configuration-item-row">
      <ConfigurationItemName
        extraProps={{
          uniquePerEnvironment:
            uniquePerEnvironment && selectedEnvironmentsList?.length > 1
        }}
        sx={{
          alignSelf: "flex-start",
          top: 10
        }}
        data-testid="duplicate-modal-configuration-name"
      >
        {configurationName}
      </ConfigurationItemName>
      {!!description.length && (
        <DescriptionTooltip
          description={description}
          additionalStyling={{
            alignSelf: "flex-start",
            left: 10,
            top: 10
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        {Object.keys(currentValuesPerEnv).map((currentEnvId: string, index) => {
          if (!uniquePerEnvironment && index >= 1) return;

          const currentValidationRules = validationRulesPerEnvironment[
            currentEnvId
          ]
            ? validationRulesPerEnvironment[currentEnvId]
            : validationRules[0];
          const validationRule =
            currentValidationRules?.validationRules || null;
          const isRequired = currentValidationRules?.isRequired || false;

          const valueToEdit =
            uniquePerEnvironment && !!currentValuesPerEnv
              ? currentValuesPerEnv[currentEnvId]
              : currentValue;
          const validationErrorMessage = uniquePerEnvironment
            ? validationErrors.currentValuesPerEnvValidationErrors[currentEnvId]
            : validationErrors.currentValueErrorMessage;

          return (
            <ItemInputContainer
              key={`duplicate-modal-configuration-item-${configurationItemId}${index}`}
              sx={{
                justifyContent: "flex-end",
                marginBottom:
                  uniquePerEnvironment && selectedEnvironmentsList?.length > 1
                    ? "15px"
                    : "5px"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <ItemInput
                  configurationValue={valueToEdit}
                  dataType={dataType}
                  required={isRequired}
                  validationRules={validationRule}
                  onInputChange={(newConfigItemValue) => {
                    updateConfigItemCurrentValue(
                      configurationItemId,
                      newConfigItemValue,
                      currentEnvId
                    );
                  }}
                  setValidationError={(message) =>
                    setValidationError(
                      message,
                      configurationItemId,
                      uniquePerEnvironment,
                      currentEnvId
                    )
                  }
                  inputWidth={inputWidth}
                />

                {!!validationErrorMessage && (
                  <Box data-testid="duplicate-modal-config-item-error">
                    <Typography color="error.main" textAlign="center">
                      {validationErrorMessage}
                    </Typography>
                  </Box>
                )}
              </Box>
              <EnvironmentTagsContainer
                sx={{
                  padding: "0px 10px",
                  alignSelf: "baseline"
                }}
                data-testid="env-tags-container"
              >
                {selectedEnvironmentsList.map((envId) => {
                  const envName =
                    environmentsDetailsById[envId]?.environmentName || "";

                  if (
                    (uniquePerEnvironment && envId === currentEnvId) ||
                    !uniquePerEnvironment
                  )
                    return renderEnvTag(envName);
                  return <></>;
                })}
              </EnvironmentTagsContainer>
            </ItemInputContainer>
          );
        })}
      </Box>
    </MainContainer>
  );
};

export default RequieredSiteFields;
