/* eslint-disable no-case-declarations */
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

import MultiselectInput from "./MultiselectInput";
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";
import { getInitialConfigurationValue } from "../../lib/siteHelpers";
import CopyToClipboardButton from "./CopyBtn";

type ItemInputProps = {
  configurationValue: string | string[] | boolean | number | null;
  dataType: ConfigurationItemDataType;
  readOnly?: boolean;
  isDisabled?: boolean;
  required: boolean;
  validationRules: ValidationRulesType | null;
  isConfigurationItemSelected?: boolean;
  onInputChange?: (value: string | string[] | boolean) => void;
  isPartOfPendingChangesList?: boolean;
  setValidationError?: (customValidationError: string) => void;
  isInNewSiteCreatingMode?: boolean;
  inputWidth?: number;
};

const BooleanConfigItemContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{
  extraProps: { isPartOfPendingChangesList?: boolean; inputWidth?: number };
}>(({ extraProps, theme }) => ({
  display: "flex",
  width: extraProps.inputWidth || 250,
  justifyContent: "center",
  border: "3px solid transparent",
  borderRadius: "10px",
  borderColor: extraProps.isPartOfPendingChangesList
    ? theme.palette.warning.dark
    : "transparent"
}));

function ItemInput(props: ItemInputProps) {
  const {
    configurationValue,
    dataType,
    validationRules,
    isDisabled,
    isConfigurationItemSelected,
    onInputChange
  } = props;

  const formatJSONDataTypeItem = (inputValue: unknown) => {
    return <TextInput {...props} inputValue={JSON.stringify(inputValue)} />;
  };
  const defaultConfigurationValue = getInitialConfigurationValue(
    dataType,
    validationRules
  );
  const inputValue = configurationValue || defaultConfigurationValue;

  const getDataTypeForItemInput = () => {
    switch (dataType) {
      case "boolean":
      case "bool":
        return (
          <BooleanConfigItemContainer
            extraProps={{
              inputWidth: props.inputWidth,
              isPartOfPendingChangesList: props.isPartOfPendingChangesList
            }}
          >
            <Switch
              checked={
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                typeof inputValue === "string"
                  ? JSON.parse(inputValue.toLowerCase())
                  : inputValue
              }
              sx={{
                margin: "0px 10px 15px 20px"
              }}
              disabled={isDisabled || false}
              onChange={(e) => {
                if (onInputChange) {
                  const newConfigItemValue = e.target.checked || false;
                  onInputChange(newConfigItemValue);
                }
              }}
            />
          </BooleanConfigItemContainer>
        );

      case "text":
      case "guid":
      case "url":
        return (
          <TextInput
            {...props}
            inputValue={inputValue}
            textLengthLimit={validationRules?.textLengthLimit}
          />
        );
      case "jsonb":
      case "json":
        return formatJSONDataTypeItem(inputValue || {});

      case "select":
      case "text[]":
      case "int[]":
      case "ordered_list":
        const selectLabels = validationRules?.allowedValues || [];

        return (
          <SelectInput
            {...props}
            value={inputValue}
            selectValues={selectLabels}
          />
        );

      case "int":
        return (
          <TextInput
            {...props}
            inputValue={inputValue}
            min={validationRules?.min}
            max={validationRules?.max}
          />
        );

      case "multiselect":
        const multiSelectLabels = validationRules?.allowedValues || [];
        if (!Array.isArray(inputValue)) return;

        return (
          <MultiselectInput
            {...props}
            value={inputValue}
            multiselectValues={multiSelectLabels}
          />
        );
    }
  };
  const shouldRenderCopyBtn =
    isConfigurationItemSelected &&
    !!inputValue &&
    ["text", "url", "json", "jsonb"].includes(dataType) &&
    typeof inputValue === "string";
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        minWidth: 250
      }}
    >
      <Box
        sx={{
          margin: "13px 0px 0px 5px",
          height: 30,
          width: 30
        }}
      >
        {shouldRenderCopyBtn ? (
          <CopyToClipboardButton value={inputValue} />
        ) : (
          <></>
        )}
      </Box>
      {getDataTypeForItemInput()}
    </Box>
  );
}

export default ItemInput;
