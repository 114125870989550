import { Auth0Provider } from "@auth0/auth0-react";

export const auth0Domain = import.meta.env.VITE_AUTH0_DOMAIN || "";
export const auth0ClientId = import.meta.env.VITE_AUTH0_CLIENTID || "";
export const auth0NameSpace = import.meta.env.VITE_AUTH0_NAMESPACE;
export const auth0Audience = import.meta.env.VITE_AUTH0_AUDIENCE || "";

const Auth0Wrapper = (props: { children: React.ReactNode }) => {
  const { children } = props;
  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/callback`,
        audience: auth0Audience
      }}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0Wrapper;
