import { styled } from "@mui/material/styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HomeIcon from "@mui/icons-material/Home";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const MainContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 50,
  flex: 1,
  flexDirection: "column"
}));

const ErrorIcon = styled(ErrorOutlineIcon)(({ theme }) => ({
  width: 150,
  height: 150,
  color: theme.palette.black.main,
  alignSelf: "center"
}));

const TitleContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 15,
  flex: 1
}));

const BackHomeBtn = styled(Button)(() => ({
  fontWeight: 200,
  width: 300,
  height: 45,
  margin: "10px",
  padding: "25px",
  fontSize: 16,
  alignSelf: "center"
}));

type ErrorPageProps = {
  errorCode?: number;
  errorMessage?: string;
};

const ErrorPage = (props: ErrorPageProps) => {
  const { errorMessage, errorCode } = props;
  return (
    <MainContainer>
      <TitleContainer>
        {errorCode ? (
          <Typography fontSize={150} fontWeight={700} data-testid="error-code">
            {errorCode}
          </Typography>
        ) : (
          <ErrorIcon data-testid="error-code-placeholder-icon" />
        )}
      </TitleContainer>
      <Box>
        <Typography fontSize={40} fontWeight={100}>
          Oops.. something has gone wrong!
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: 1,
          marginTop: 10
        }}
      >
        <Typography fontSize={22} fontWeight={300}>
          Please refresh page or go back home to try again.
        </Typography>
        {errorMessage && (
          <Box
            sx={{
              margin: 2,
              marginLeft: 0
            }}
          >
            <Typography fontSize={18} fontWeight={200}>
              Error details: <i>{errorMessage}</i>
            </Typography>
          </Box>
        )}
        <BackHomeBtn
          color="secondary"
          variant="contained"
          startIcon={<HomeIcon />}
          href="/"
        >
          Back Home
        </BackHomeBtn>
      </Box>
    </MainContainer>
  );
};

export default ErrorPage;
