import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { useDispatch } from "react-redux";

import mainCustomDrawerReducer from "./components/drawer/mainCustomDrawerSlice";
import homepageReducer from "./components/home/homepageSlice";
import siteReducer from "./components/site/siteSlice";
import userReducer from "./components/login/userSlice";
import messagePopperReducer from "./components/messagePopper/messagePopperSlice";
import { clientConfigApi } from "./redux/api";

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["mainCustomDrawer", "api", "site", "messagePopper"]
};

const mainReducer = combineReducers({
  mainCustomDrawer: mainCustomDrawerReducer,
  homepage: homepageReducer,
  site: siteReducer,
  user: userReducer,
  messagePopper: messagePopperReducer,
  [clientConfigApi.reducerPath]: clientConfigApi.reducer
});

const persistedReducer = persistReducer(persistConfig, mainReducer);
export const createReduxStore = () => {
  const store = configureStore({
    reducer: persistedReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
      }).concat(clientConfigApi.middleware)
  });
  const persistor = persistStore(store);

  return { store, persistor };
};

const { store, persistor } = createReduxStore();

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export { store, persistor };
