import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

type SelectInputPropsType = {
  value: string;
  selectValues: string[];
  readOnly?: boolean;
  isDisabled?: boolean;
  onInputChange?: (value: string | string[]) => void;
  isPartOfPendingChangesList?: boolean;
  inputWidth?: number;
};

const SelectInput = (props: SelectInputPropsType) => {
  const {
    value,
    selectValues,
    isDisabled,
    readOnly,
    onInputChange,
    isPartOfPendingChangesList,
    inputWidth
  } = props;
  const warningColor = isPartOfPendingChangesList ? "warning.dark" : "#E2E2E2";
  return (
    <Select
      value={value}
      onChange={(e) => {
        if (!readOnly && onInputChange) {
          onInputChange(e.target.value);
        }
      }}
      disabled={isDisabled || readOnly}
      sx={{
        backgroundColor: isDisabled ? "gray.light" : "white.main",
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
          opacity: isDisabled ? 0.3 : 1
        },
        "&.MuiOutlinedInput-root": {
          "& .MuiOutlinedInput-notchedOutline": {
            top: 0,
            borderWidth: isPartOfPendingChangesList ? "3px" : "1px",
            borderRadius: isPartOfPendingChangesList ? "10px" : "inherit"
          },
          "&.Mui-disabled fieldset": {
            borderColor: warningColor
          }
        },
        "& legend": { display: "none" },
        height: 50,
        width: inputWidth || 250,
        padding: "5px"
      }}
      inputProps={{
        style: {
          padding: "10px 15px"
        }
      }}
    >
      {selectValues.map((value: string, index: number) => (
        <MenuItem key={`${value}-${index}`} value={value}>
          {value}
        </MenuItem>
      ))}
    </Select>
  );
};
export default SelectInput;
