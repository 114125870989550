import { createSlice } from "@reduxjs/toolkit";

export type UserStateType = {
  userProfile: UserProfile;
  accessToken: string;
};

const initialState: UserStateType = {
  userProfile: {
    userId: "",
    email: "",
    name: "",
    roles: []
  },
  accessToken: ""
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    saveOrUpdateUserDetails(state, action) {
      const userProfile = action.payload as UserProfile & {
        accessToken: string;
      };
      state.userProfile = userProfile;
      state.accessToken = userProfile.accessToken;
    },
    resetUserState(state) {
      state.userProfile = initialState.userProfile;
      state.accessToken = "";
    }
  }
});

export const { saveOrUpdateUserDetails, resetUserState } = userSlice.actions;
export default userSlice.reducer;
