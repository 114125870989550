import { useGetPartnersListQuery } from "../../redux/api/partner";
import { SelectedSingleDrawerDataType } from "./mainCustomDrawerSlice";

import CustomDrawerContent from "./CustomDrawerContent";

type PartnerDrawerContentProps = {
  onDrawerMenuBtnClick: (
    selectedDrawerOption: SelectedSingleDrawerDataType,
    parentDetails?: SelectedSingleDrawerDataType | null
  ) => void;
  drawerType: DrawerType;
};

function PartnerDrawerContent(props: PartnerDrawerContentProps) {
  const {
    data = [],
    isFetching,
    isLoading
  } = useGetPartnersListQuery(undefined, {
    refetchOnMountOrArgChange: true
  });

  return (
    <CustomDrawerContent
      {...props}
      isDataLoading={isFetching || isLoading}
      drawerContentData={data as unknown as CustomDrawerContentType[]}
    />
  );
}

export default PartnerDrawerContent;
