import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import Collapse from "@mui/material/Collapse";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import {
  getNumbersOfDrawersWithContentSelected,
  getDrawerNameToDisplayByType
} from "../../lib/helpers";
import {
  toggleSelectedDrawer,
  onDrawerOptionBtnClick,
  SelectedSingleDrawerDataType
} from "./mainCustomDrawerSlice";
import {
  getRootDrawer,
  getCurrentDrawerStateProperties,
  getCurrentOpenDrawerType,
  getSelectedDrawersData
} from "../../redux/selectors/drawerSelectors";
import PartnersDrawerContent from "./PartnersDrawerContent";
import ClientsDrawerContent from "./ClientsDrawerContent";
import SitesDrawerContent from "./SitesDrawerContent";
import { saveRecentSearches } from "../home/homepageSlice";
import { drawerWidth, navBarHeight } from "../../lib/theme";
import { useAppDispatch } from "../../store";

type CustomDrawerProps = {
  drawerTypeName: DrawerType;
};

const CustomDrawerPreview = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{
  extraProps: { isDrawerDisabled: boolean; shouldDisplayExpandedUI: boolean };
}>(({ extraProps }) => ({
  display: "flex",
  flexFlow: "wrap",
  justifyContent: "center",
  alignItems: "flex-start",
  alignContent: "space-between",
  flex: extraProps.shouldDisplayExpandedUI ? 2 : 1,
  cursor: extraProps.isDrawerDisabled ? "not-allowed" : "pointer",
  minHeight: 57
}));

const CustomDrawerTypeText = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{
  extraProps: { isDrawerOpen: boolean; shouldDisplayExpandedUI: boolean };
}>(({ extraProps }) => ({
  transform: extraProps.isDrawerOpen ? "none" : "rotate(-90deg)",
  transformOrigin: extraProps.shouldDisplayExpandedUI ? "left" : "center",
  margin: "auto"
}));

const CustomDrawerMainContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{
  extraProps: { isDrawerOpen: boolean; isDrawerDisabled: boolean };
}>(({ extraProps, theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: extraProps.isDrawerOpen ? drawerWidth : 60,
  border: "1px solid rgba(0,0,0,0.2)",
  borderLeft: "none",
  backgroundColor: extraProps.isDrawerDisabled
    ? theme.palette.gray.light
    : theme.palette.background.paper,
  "&:hover": {
    cursor: extraProps.isDrawerDisabled ? "not-allowed" : "default"
  }
}));

function CustomDrawer(props: CustomDrawerProps) {
  const { drawerTypeName } = props;
  const dispatch = useAppDispatch();

  const {
    isOpen,
    isDisabled: isDrawerDisabled,
    nextDrawer,
    previousDrawer
  } = useSelector(getCurrentDrawerStateProperties(drawerTypeName));

  const rootDrawer = useSelector(getRootDrawer());
  const currentOpenDrawerType = useSelector(getCurrentOpenDrawerType);
  const selectedDrawersData = useSelector(getSelectedDrawersData);

  const drawerNameToDisplay = getDrawerNameToDisplayByType(drawerTypeName);

  const numberOfDrawersWithSelectedContent =
    getNumbersOfDrawersWithContentSelected(selectedDrawersData);

  const isRootDrawer = rootDrawer.drawerName === drawerTypeName;
  const isCurrentDrawerSelected = currentOpenDrawerType === drawerTypeName;
  const isDrawerOpen = isOpen && !isDrawerDisabled;

  const shouldDisplayExpandedUI =
    numberOfDrawersWithSelectedContent > 0 &&
    !isDrawerOpen &&
    selectedDrawersData[drawerTypeName];

  const toggleDrawer = (
    drawerTypeName: DrawerType,
    selectedDrawerValue?: boolean
  ): void => {
    dispatch(
      toggleSelectedDrawer({
        drawerType: drawerTypeName,
        isDrawerOpenValue: selectedDrawerValue || false
      })
    );
  };

  const onArrowBtnClick = () => {
    if (isDrawerDisabled) return;

    if (previousDrawer && isCurrentDrawerSelected) {
      toggleDrawer(drawerTypeName, false);
      //@ts-ignore
      toggleDrawer(previousDrawer, true);
    } else if (
      // if we are selecting the root drawer by clicking on "arrow back" icon to close all drawers
      isRootDrawer &&
      (isCurrentDrawerSelected ||
        (!currentOpenDrawerType && !selectedDrawersData[drawerTypeName]))
    ) {
      toggleDrawer(drawerTypeName, !isDrawerOpen);
    } else if (
      // if the current drawer we are selecting is "site"/last after returning from a site details view
      !currentOpenDrawerType &&
      selectedDrawersData[drawerTypeName] &&
      drawerTypeName === "site"
    ) {
      toggleDrawer("site", true);
      // if we are selecting any other drawer that is not the "site"/last drawer
      // allows users to switch drawers at will regardless of order
    } else if (drawerTypeName !== "site" && !isCurrentDrawerSelected) {
      toggleDrawer(drawerTypeName, true);
    }
  };

  const onDrawerMenuBtnClick = (
    selectedDrawerOption: SelectedSingleDrawerDataType,
    parentDetails?: SelectedSingleDrawerDataType | null
  ) => {
    dispatch(
      onDrawerOptionBtnClick({
        drawerType: drawerTypeName,
        selectedDrawerOption,
        nextDrawerTypeToOpen: nextDrawer
      })
    );
    if (parentDetails)
      dispatch(
        saveRecentSearches({
          siteId: selectedDrawerOption.id,
          siteName: selectedDrawerOption.name,
          clientName: parentDetails.name,
          clientId: parentDetails.id
        })
      );
  };

  const renderDrawerContentList = () => {
    switch (drawerTypeName) {
      case "partner":
        return (
          <PartnersDrawerContent
            onDrawerMenuBtnClick={onDrawerMenuBtnClick}
            drawerType={drawerTypeName}
          />
        );
      case "client":
        return (
          <ClientsDrawerContent
            onDrawerMenuBtnClick={onDrawerMenuBtnClick}
            drawerType={drawerTypeName}
          />
        );
      case "site":
        return (
          <SitesDrawerContent
            onDrawerMenuBtnClick={onDrawerMenuBtnClick}
            drawerType={drawerTypeName}
          />
        );
    }
  };

  const fullDrawerNameToDisplay = selectedDrawersData[drawerTypeName]
    ? `${drawerNameToDisplay} - ${selectedDrawersData[drawerTypeName]?.name}`
    : drawerNameToDisplay;

  return (
    <CustomDrawerMainContainer
      extraProps={{
        isDrawerOpen,
        isDrawerDisabled
      }}
      data-testid={`custom-drawer-${drawerTypeName}`}
    >
      <Toolbar sx={{ flex: `0 1 ${navBarHeight}` }} />

      <CustomDrawerPreview
        onClick={onArrowBtnClick}
        extraProps={{
          shouldDisplayExpandedUI: !!shouldDisplayExpandedUI,
          isDrawerDisabled
        }}
        data-testid={`custom-drawer-${drawerTypeName}-toggle-btn`}
      >
        <ListItemButton
          sx={{
            right: 10,
            cursor: "inherit",
            "&:hover": {
              backgroundColor: "transparent"
            }
          }}
        >
          {isDrawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </ListItemButton>
        <CustomDrawerTypeText
          primary={fullDrawerNameToDisplay}
          extraProps={{
            shouldDisplayExpandedUI: !!shouldDisplayExpandedUI,
            isDrawerOpen
          }}
          sx={{
            "& span": {
              fontSize: 16,
              marginRight: isDrawerOpen ? "none" : "10px",
              whiteSpace: isDrawerOpen ? "normal" : "nowrap"
            }
          }}
          data-testid={`custom-drawer-title-${drawerTypeName}`}
        />
      </CustomDrawerPreview>

      {isDrawerOpen && <Divider sx={{ margin: 1 }} />}

      <Collapse
        in={isDrawerOpen}
        orientation="horizontal"
        sx={{
          flex: isDrawerOpen ? "85%" : 4,
          transition: "450ms cubic-bezier(0.23, 1, 0.32, 1) 2ms",
          "& .MuiCollapse-wrapperInner": {
            width: "100%",
            height: "100%"
          }
        }}
      >
        {renderDrawerContentList()}
      </Collapse>
    </CustomDrawerMainContainer>
  );
}

export default CustomDrawer;
