import Box from "@mui/material/Box";
import { useSelector } from "react-redux";

import CustomDrawer from "./CustomDrawer";
import { getAllDrawerState } from "../../redux/selectors/drawerSelectors";
import { navBarHeight } from "../../lib/theme";

function MainCustomDrawer() {
  const drawersState = useSelector(getAllDrawerState);

  return (
    <Box
      id="MainCustomDrawer"
      padding={0}
      sx={{
        display: "flex",
        height: `calc(100vh - ${navBarHeight * 2})`
      }}
    >
      {drawersState.partner?.shouldDisplay && (
        <CustomDrawer drawerTypeName="partner" />
      )}
      {drawersState.client?.shouldDisplay && (
        <CustomDrawer drawerTypeName="client" />
      )}
      {drawersState.site?.shouldDisplay && (
        <CustomDrawer drawerTypeName="site" />
      )}
    </Box>
  );
}

export default MainCustomDrawer;
