import { styled } from "@mui/material/styles";
import { useState } from "react";

import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

import { renderEnvTag } from "../site/ConfigurationItem";

type AuditLogRowsData = {
  configName: string;
  originalValue: string;
  currentValue: string;
  environments: EnvTagNamesType[];
  date: number;
  user: string;
  configGroupName?: string;
};

type AuditLogTableProps = {
  columnHeaderNames: string[];
  rowsData: AuditLogRowsData[];
};

const AuditLogTableContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "80%",
  flex: 1,
  flexDirection: "column",
  padding: 12,
  borderRadius: 8,
  margin: 8,
  background: theme.palette.background.paper
}));

const AuditLogTable = (props: AuditLogTableProps) => {
  const { columnHeaderNames, rowsData } = props;
  const [currentPage, setNewPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(8);

  const handleChangePage = (_e: unknown, newPage: number) => {
    setNewPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setNewPage(0);
  };

  return (
    <AuditLogTableContainer>
      <TableContainer sx={{ overflow: "scroll" }}>
        <Table
          stickyHeader
          sx={{
            "& th": {
              backgroundColor: (theme) => theme.palette.background.paper
            }
          }}
        >
          <TableHead>
            <TableRow>
              {columnHeaderNames.map((columnName) => {
                return (
                  <TableCell key={`header-column-${columnName}`} align="center">
                    {columnName}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody sx={{ overflowX: "scroll" }}>
            {rowsData
              .slice(
                currentPage * rowsPerPage,
                currentPage * rowsPerPage + rowsPerPage
              )
              .map((row) => {
                const {
                  configName,
                  originalValue,
                  configGroupName,
                  currentValue,
                  date,
                  environments,
                  user
                } = row;
                return (
                  <TableRow key={row.configName} sx={{ minHeight: 60 }}>
                    <TableCell width={50} align="center">
                      {configName}
                    </TableCell>
                    <TableCell align="center">{originalValue}</TableCell>
                    <TableCell align="center">{currentValue}</TableCell>
                    <TableCell
                      align="left"
                      component={() => {
                        return (
                          <TableCell align="center">
                            <Box
                              key={`env-tags`}
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "space-evenly",
                                alignItems: "center"
                              }}
                            >
                              {environments.map((envName) => {
                                return renderEnvTag(envName, 23, 23);
                              })}
                            </Box>
                          </TableCell>
                        );
                      }}
                    />
                    <TableCell align="center">{date}</TableCell>
                    <TableCell align="center">{user}</TableCell>
                    <TableCell width={50} align="center">
                      {configGroupName || "N/A"}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[8]}
        component="div"
        count={rowsData.length}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </AuditLogTableContainer>
  );
};

export default AuditLogTable;
