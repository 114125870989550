import { createSelector } from "reselect";

import { UserStateType } from "../../components/login/userSlice";
import { RootState } from "../../store";

export const getUserProfile = createSelector(
  (state: RootState) => state.user,
  (user: UserStateType) => ({
    ...user.userProfile,
    accessToken: user.accessToken
  })
);

export const checkUserLoggedInStatus = (state: RootState) =>
  !!state.user.accessToken;
