import { useEffect } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

import SiteConfigurations from "./SiteConfigurations";
import ConfigurationItems from "./ConfigurationItems";
import {
  getPendingConfigurationItemsList,
  getSelectedSiteSection,
  getSitetoDuplicateOrcreateInfo
} from "../../redux/selectors/siteSelectors";
import Header from "./Header";
import PendingChangesList from "./PendingChangesList";
import { clearSiteToDuplicateOrCreateInstance } from "./siteSlice";
import { useAppDispatch } from "../../store";
import DeploymentEventsStatusDrawer from "../deploymentsStatus/DeploymentEventsStatusDrawer";
import { useGetDeploymentsStatusQuery } from "../../redux/api/site";
import { getSelectedDrawersData } from "../../redux/selectors/drawerSelectors";

export const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: "1 0 0",
  alignSelf: "stretch",
  padding: 10,
  borderRadius: 10,
  background: theme.palette.secondary.dark,
  margin: 15,
  overflow: "hidden"
}));

const SecondaryContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  alignSelf: "stretch",
  border: `1px 0px 0px 0px`,
  borderRadius: 10,
  background: theme.palette.background.paper,
  margin: 10,
  overflowY: "hidden",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  height: "100%"
}));

function MainSiteContainer() {
  const dispatch = useAppDispatch();
  const selectedSiteSection = useSelector(getSelectedSiteSection);
  const isSiteSectionSelected = !!selectedSiteSection.id;
  const pendingConfigurationChangesList = useSelector(
    getPendingConfigurationItemsList
  );
  const siteToDuplicateOrCreate = useSelector(getSitetoDuplicateOrcreateInfo);
  const isInNewSiteCreatingMode =
    !!siteToDuplicateOrCreate?.generalSiteInformation?.siteId;

  const selectedClientDrawerData = useSelector(getSelectedDrawersData).site;
  useGetDeploymentsStatusQuery(
    {
      siteId: selectedClientDrawerData?.id || ""
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !selectedClientDrawerData?.id || isInNewSiteCreatingMode
    }
  );

  const shouldRenderPendingChanges = !!Object.keys(
    pendingConfigurationChangesList
  ).length;

  useEffect(() => {
    return () => {
      dispatch(clearSiteToDuplicateOrCreateInstance());
    };
  }, []);

  return (
    <MainContainer>
      <Header shouldRenderPendingChanges={shouldRenderPendingChanges} />
      <SecondaryContainer
        sx={{
          justifyContent: "space-between"
        }}
      >
        <SiteConfigurations siteToDuplicateOrCreate={siteToDuplicateOrCreate} />
        {isSiteSectionSelected && (
          <ConfigurationItems
            siteToDuplicateOrCreate={siteToDuplicateOrCreate}
          />
        )}
        <Stack flexDirection="row" justifyContent="flex-end">
          {shouldRenderPendingChanges && (
            <PendingChangesList
              siteToDuplicateOrCreate={siteToDuplicateOrCreate}
            />
          )}
          {!isInNewSiteCreatingMode && <DeploymentEventsStatusDrawer />}
        </Stack>
      </SecondaryContainer>
    </MainContainer>
  );
}

export default MainSiteContainer;
