/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useSelector } from "react-redux";
import { useEffect } from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import { getSelectedDrawersData } from "../../redux/selectors/drawerSelectors";
import DuplicateSiteModalContent from "./DuplicateSiteContent";
import RequieredDuplicateSiteInformation from "./RequieredDuplicateSiteInformation";
import { getSitetoDuplicateOrcreateInfo } from "../../redux/selectors/siteSelectors";
import { LoadingSpinner } from "../site/ConfigurationItems";
import { useGetDuplicateSiteDetailsQuery } from "../../redux/api/site";
import { clearSelectedEnvironmentsListToDuplicate } from "../site/siteSlice";
import { useAppDispatch } from "../../store";

const MainContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxWidth: 1200,
  minWidth: 600,
  height: "90%",
  background: theme.palette.background.paper,
  borderRadius: "10px",
  overflow: "hidden"
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: 65,
  background: theme.palette.primary.main,
  color: theme.palette.white.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderTopRightRadius: "10px",
  borderTopLeftRadius: "10px",
  padding: "10px 5px"
}));

const ContentContainer = styled(Box)(() => ({
  display: "flex",
  height: "90%",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column"
}));

export const ModalMainContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  height: "100%",
  width: "100%"
}));

type DuplicateSiteModalProps = {
  isModalOpen: boolean;
  handleModalClose: () => void;
};

const DuplicateSiteModal = (props: DuplicateSiteModalProps) => {
  const { isModalOpen, handleModalClose } = props;
  const selectedDrawersData = useSelector(getSelectedDrawersData);
  const siteToDuplicateOrCreate = useSelector(getSitetoDuplicateOrcreateInfo);
  const dispatch = useAppDispatch();

  const { selectedEnvironmentsList, generalSiteInformation } =
    siteToDuplicateOrCreate;
  const isInNewSiteCreatingMode = !!generalSiteInformation?.siteId;
  const siteToDuplicateFrom = selectedDrawersData.site;
  const { name = "", id = "" } = siteToDuplicateFrom || {};
  const hasSelectedEnvironmentsListToDuplicateTo =
    !!selectedEnvironmentsList.length;

  const clientName = selectedDrawersData.client?.name || "";

  const modalHeaderTitle = `Duplicate ${name} Configuration`;

  const { data, isLoading } = useGetDuplicateSiteDetailsQuery(
    {
      siteId: id,
      envIdsList: selectedEnvironmentsList
    },
    {
      skip: !isModalOpen || !id || !hasSelectedEnvironmentsListToDuplicateTo,
      refetchOnMountOrArgChange: true
    }
  );
  useEffect(() => {
    if (!isInNewSiteCreatingMode) {
      dispatch(clearSelectedEnvironmentsListToDuplicate());
    }
  }, [isModalOpen]);

  return (
    <Modal
      open={isModalOpen}
      onClose={handleModalClose}
      aria-labelledby="duplicate-site-modal"
      data-testid="duplicate-site-modal"
      sx={{
        height: !hasSelectedEnvironmentsListToDuplicateTo ? "400px" : "95%",
        top: !hasSelectedEnvironmentsListToDuplicateTo ? "20%" : "0%"
      }}
    >
      <MainContainer
        sx={{
          width: !hasSelectedEnvironmentsListToDuplicateTo ? "30%" : "50%"
        }}
      >
        <HeaderContainer>
          <Typography
            fontSize={"1.5rem"}
            fontWeight={500}
            sx={{
              textAlign: "center"
            }}
            data-testid="modal-header-title"
          >
            {modalHeaderTitle}
          </Typography>
        </HeaderContainer>

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <ContentContainer>
            {!hasSelectedEnvironmentsListToDuplicateTo ? (
              <RequieredDuplicateSiteInformation
                handleModalClose={handleModalClose}
              />
            ) : (
              <DuplicateSiteModalContent
                duplicateSiteData={data as FormattedDuplicateSiteResponse}
                clientName={clientName}
                handleModalClose={handleModalClose}
              />
            )}
          </ContentContainer>
        )}
      </MainContainer>
    </Modal>
  );
};

export default DuplicateSiteModal;
