import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";

import Navbar from "../components/Navbar";

export const MainContainer = styled(Box)(() => ({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh"
}));

export const LoginBtn = styled(Button)(() => ({
  padding: "15px",
  minWidth: 250,
  fontWeight: "300",
  fontSize: 20
}));

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <MainContainer>
      <Navbar />
      <LoginBtn
        color="secondary"
        variant="contained"
        data-testid="login-btn"
        onClick={() =>
          setTimeout(() => {
            loginWithRedirect();
          }, 0)
        }
      >
        Log In
      </LoginBtn>
    </MainContainer>
  );
};

export default Login;
