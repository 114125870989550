import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
// redux
import { Provider } from "react-redux";
import { persistor, store } from "./store";

// components
import AppContainer from "./App";
import { PersistGate } from "redux-persist/es/integration/react";

import "./assets/main.css";
import Auth0Wrapper from "./components/login/Auth0Wrapper";

const isDevelopmentMode = import.meta.env.DEV || false;
const runtineEnv = (import.meta.env.RUNTIME_ENVIRONMENT || "") as string;
const releaseVersion = (import.meta.env.VITE_VERSION_TAG || "") as string;

export const envName = isDevelopmentMode ? "development" : runtineEnv;

Sentry.init({
  dsn: "https://a2266753f2b5b0e49d805fdec2a7c7da@o262631.ingest.sentry.io/4506560606109696",
  release: releaseVersion,
  environment: envName,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  tracesSampleRate: isDevelopmentMode ? 0 : 0.5,
  //Sentry Replay
  replaysSessionSampleRate: isDevelopmentMode ? 0 : 0.1,
  replaysOnErrorSampleRate: isDevelopmentMode ? 0 : 1.0,
  beforeSend(event) {
    if (isDevelopmentMode) {
      console.debug("Sentry event:", event);
      return null;
    }
    return event;
  }
});

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

(() => {
  root.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Auth0Wrapper>
          <AppContainer />
        </Auth0Wrapper>
      </PersistGate>
    </Provider>
  );
})();
