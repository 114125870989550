import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

import SitesSearchBar from "./SitesSearchBar";
import RecentSearches from "./RecentSearches";

import homepageImg from "../../assets/ASHomePage.avif";
import { mobileWidth } from "../../lib/theme";

const MainContainer = styled(Box)(() => ({
  display: "flex",
  flex: "auto"
}));
const SecondaryContainer = styled(Box)(() => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  margin: "60px 30px 30px 30px"
}));

const HeaderContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingBottom: 10,
  flex: "auto"
}));

const UserActionsContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flex: "2 2 auto"
}));

const UserActionsButtonsContainer = styled(Box)(() => ({
  display: "flex",
  margin: "20px",
  justifyContent: "center"
}));

const UserActionsButton = styled(Button)(() => ({
  fontWeight: 300,
  minWidth: 200,
  height: 45,
  margin: "10px"
}));

const SearchBarTitle = styled(Typography)(() => ({
  fontSize: 14,
  alignItems: "center",
  marginBottom: "5px 5px 5px 0px"
}));

const MainTitle = styled(Typography)(() => ({
  fontWeight: 200,
  fontSize: 18,
  padding: "5px",
  alignSelf: "flex-start",
  position: "relative",
  top: 15
}));

const HomePageContent = () => {
  const isMobile = useMediaQuery(mobileWidth);
  return (
    <MainContainer>
      <SecondaryContainer>
        <HeaderContainer data-testid="main-title">
          <MainTitle>Welcome to</MainTitle>
          <Typography fontSize={isMobile ? 30 : 40} fontWeight="bold">
            Client Configuration Tool
          </Typography>
        </HeaderContainer>
        <UserActionsContainer data-testid="user-actions">
          <SearchBarTitle>Choose and navigate to selected site:</SearchBarTitle>
          <SitesSearchBar />
          <UserActionsButtonsContainer>
            <UserActionsButton
              color="secondary"
              variant="contained"
              startIcon={<AddIcon />}
              disabled
            >
              Create New Site
            </UserActionsButton>
          </UserActionsButtonsContainer>
        </UserActionsContainer>
        <RecentSearches />
      </SecondaryContainer>
      {!isMobile && (
        <img
          src={homepageImg}
          alt="homepage-img"
          style={{
            objectFit: "cover",
            objectPosition: "center",
            height: "100%",
            width: "50%"
          }}
        />
      )}
    </MainContainer>
  );
};

export default HomePageContent;
