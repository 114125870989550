import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

type LoadingComponentPropsType = {
  numberOfRows: number;
  width?: number;
};
const Loading = (props: LoadingComponentPropsType) => {
  const { numberOfRows, width } = props;
  const rowsArr = Array.from(Array(numberOfRows).keys());

  return (
    <Box>
      {rowsArr.map((el) => {
        return (
          <Skeleton
            height={50}
            variant="text"
            width={width || "100%"}
            key={`loading-row-${el}`}
            data-testid="loading-row"
          />
        );
      })}
    </Box>
  );
};

export default Loading;
