import { v4 } from "uuid";
import { SelectedDrawersDataType } from "../components/drawer/mainCustomDrawerSlice";

export const getDrawerNameToDisplayByType = (
  drawerTypeName: DrawerType
): string => {
  switch (drawerTypeName) {
    case "partner":
      return "Partner";
    case "client":
      return "Client";
    case "site":
      return "Site";
  }
};

export const getNumbersOfDrawersWithContentSelected = (
  selectedDrawersData: SelectedDrawersDataType
): number => Object.values(selectedDrawersData).filter((el) => !!el).length;

export const generateUUID = (): string => v4();

export const isURL = (str: string): boolean => {
  const expression =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
  const regex = new RegExp(expression);
  return !!str.match(regex);
};

const convertToSnakeCase = (key: string) =>
  key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const convertObjectToSnakeCase = (item: unknown) => {
  if (Array.isArray(item)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return item.map((el: unknown) => convertObjectToSnakeCase(el));
  }
  if (typeof item === "function" || !(item instanceof Object)) {
    return item;
  }
  return Object.fromEntries(
    Object.entries(item).map(([key, value]) => {
      return [convertToSnakeCase(key), convertObjectToSnakeCase(value)];
    })
  );
};
