import { createSlice } from "@reduxjs/toolkit";
import { navigateToRecentSiteSearch } from "../drawer/mainCustomDrawerSlice";
import { resetUserState } from "../login/userSlice";

export type RecentSearchType = {
  clientName: string;
  siteName: string;
  siteId: string;
  clientId: string;
};

export type HomePageStateType = {
  recentSearches: RecentSearchType[];
};

const initialState: HomePageStateType = {
  recentSearches: []
};

const homepageSlice = createSlice({
  name: "homepage",
  initialState: initialState,
  reducers: {
    saveRecentSearches(state, action) {
      const selectedSiteData = action.payload as RecentSearchType;
      if (state.recentSearches.length === 5) {
        state.recentSearches.pop();
      }
      state.recentSearches.unshift(selectedSiteData);
    }
  },

  extraReducers: (builder) => {
    //once navigateToRecentSiteSearch gets dispatched this homepage reducer will update the recent searches
    builder.addCase(navigateToRecentSiteSearch.type, (state, action) => {
      const selectedSiteData = action.payload as RecentSearchType;
      if (state.recentSearches.length === 5) {
        state.recentSearches.pop();
      }
      state.recentSearches.unshift(selectedSiteData);
    });
    builder.addCase(resetUserState.type, (state) => {
      state.recentSearches = [];
    });
  }
});

export const { saveRecentSearches } = homepageSlice.actions;
export default homepageSlice.reducer;
