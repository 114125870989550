import { SelectedSingleDrawerDataType } from "./mainCustomDrawerSlice";
import CustomDrawerContent from "./CustomDrawerContent";
import { useGetSitesListByClientQuery } from "../../redux/api/site";
import { getSelectedDrawersData } from "../../redux/selectors/drawerSelectors";
import { useSelector } from "react-redux";

type SitesDrawerContenProps = {
  onDrawerMenuBtnClick: (
    selectedDrawerOption: SelectedSingleDrawerDataType,
    parentDetails?: SelectedSingleDrawerDataType | null
  ) => void;
  drawerType: DrawerType;
};

function SitesDrawerContent(props: SitesDrawerContenProps) {
  const selectedClientDrawerData = useSelector(getSelectedDrawersData).client;
  const clientId = selectedClientDrawerData?.id || "";
  const { data = [], isLoading } = useGetSitesListByClientQuery(
    {
      clientId
    },
    {
      skip: !clientId,
      refetchOnMountOrArgChange: true
    }
  );

  return (
    <CustomDrawerContent
      {...props}
      isDataLoading={isLoading}
      drawerContentData={data as unknown as CustomDrawerContentType[]}
    />
  );
}

export default SitesDrawerContent;
