import { useAuth0 } from "@auth0/auth0-react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { saveOrUpdateUserDetails } from "./userSlice";
import { auth0NameSpace } from "./Auth0Wrapper";
import { useAppDispatch } from "../../store";

const AuthCallback = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, getAccessTokenSilently, error } = useAuth0();

  useEffect(() => {
    if (user) {
      getAccessTokenSilently().then((accessToken) => {
        if (!accessToken) {
          throw new Error("Acces token not found");
        }
        const userProfile = {
          userId: (user[`${auth0NameSpace}/userId`] || "") as string,
          email: user.email || "",
          name: user.name || "",
          roles: (user[`${auth0NameSpace}/roles`] || []) as string[],
          // eslint-disable-next-line no-underscore-dangle
          accessToken
        };

        dispatch(saveOrUpdateUserDetails(userProfile));
        navigate("/");
      });
    }
  }, [user]);

  useEffect(() => {
    if (error) {
      navigate("/login");
    }
  }, [error]);

  return (
    <Backdrop
      sx={{
        color: (theme) => theme.palette.background.paper,
        zIndex: (theme) => theme.zIndex.drawer + 2
      }}
      open
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default AuthCallback;
