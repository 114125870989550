import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";

import { ModalMainContent } from "./DuplicateSiteModal";
import DuplicateModalActionButtons from "./DuplicateModalActionButtons";
import { updateSelectedEnvironmentsListToDuplicate } from "../site/siteSlice";
import { useGetEnvironmentsListQuery } from "../../redux/api/environments";
import { useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import { getSelectedDrawersData } from "../../redux/selectors/drawerSelectors";

const EnvValuesContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  justifyContent: "center",
  alignItems: "center"
}));

const EnvSelectionContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  flex: 0.9,
  justifyContent: "center"
}));

const RequieredDuplicateSiteInformation = (props: {
  handleModalClose: () => void;
}) => {
  const { handleModalClose } = props;
  const [selectedEnvListToDuplicate, setSelectedEnvironments] = useState<
    string[]
  >([]);
  const dispatch = useAppDispatch();
  const selectedDrawersData = useSelector(getSelectedDrawersData);

  const { data } = useGetEnvironmentsListQuery(undefined);
  const environmentsData = (data || {}) as EnvironmentsListType;

  const { environmentsDetailsById = {} }: EnvironmentsListType =
    environmentsData;

  const updateSelectedEnvList = (envId: string) => {
    if (selectedEnvListToDuplicate.includes(envId)) {
      const updatedSelectedEnvLisToDuplicateTo =
        selectedEnvListToDuplicate.filter(
          (selectedEnvId) => selectedEnvId !== envId
        );
      setSelectedEnvironments(updatedSelectedEnvLisToDuplicateTo);
    } else {
      setSelectedEnvironments((prevEnvList) => [...prevEnvList, envId]);
    }
  };

  const submitListOfSelectedEnvironmentsToDuplicateTo = () => {
    if (selectedEnvListToDuplicate.length) {
      dispatch(
        updateSelectedEnvironmentsListToDuplicate({
          selectedEnvListToDuplicate,
          fromSiteId: selectedDrawersData.site?.id || ""
        })
      );
    }
  };

  const clearModalStatus = () => {
    setSelectedEnvironments([]);
  };

  useEffect(() => {
    return () => {
      clearModalStatus();
    };
  }, []);

  return (
    <ModalMainContent
      sx={{
        alignItems: "center",
        flex: 1
      }}
      data-testid="env-selection-content"
    >
      <EnvSelectionContainer>
        <Typography
          sx={{
            fontSize: "1.2rem",
            textAlign: "center",
            margin: "5px 20px"
          }}
        >
          Please selected the environments you wish to duplicate to:
        </Typography>
        <EnvValuesContainer>
          {Object.keys(environmentsDetailsById).map((envId) => {
            const { environmentName, enabled = false } =
              environmentsDetailsById[envId];
            const isEnvSelected = !!selectedEnvListToDuplicate.includes(envId);
            return (
              <Box
                key={`env-container-checkbox-${envId}`}
                data-testid={"env-container-checkbox"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  opacity: !enabled ? 0.2 : 2
                }}
              >
                <Checkbox
                  label={environmentName}
                  aria-label={environmentName}
                  id={`env-checkbox-${envId}`}
                  checked={isEnvSelected}
                  onClick={() => updateSelectedEnvList(envId)}
                  disabled={!enabled}
                />
                <Typography
                  id={`env-label-${envId}`}
                  sx={{ minWidth: "100px" }}
                >
                  {environmentName}
                </Typography>
              </Box>
            );
          })}
        </EnvValuesContainer>
      </EnvSelectionContainer>
      <DuplicateModalActionButtons
        additionStyling={{
          marginBottom: "15px"
        }}
        onSubmitCb={submitListOfSelectedEnvironmentsToDuplicateTo}
        onCancelCb={handleModalClose}
        submitBtnName="Next"
      />
    </ModalMainContent>
  );
};

export default RequieredDuplicateSiteInformation;
