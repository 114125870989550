import { useEffect } from "react";
import { SiteToDuplicateOrCreateType } from "../components/site/siteSlice";
import { convertObjectToSnakeCase } from "../lib/helpers";
import {
  useLazyGetSitesListByClientQuery,
  useLazyPostDuplicateOrCreateSiteQuery
} from "../redux/api/site";

const usePostDuplicateOrCreateSiteHook = (
  siteToDuplicateOrCreate: SiteToDuplicateOrCreateType,
  pendingConfigurationChangesList: PendingChangeType[],
  clientId?: string
) => {
  const { generalSiteInformation, fromSiteId, selectedEnvironmentsList } =
    siteToDuplicateOrCreate;
  const isInNewSiteCreatingMode = !!generalSiteInformation?.siteId;

  const configurationItems: ConfigurationItemType[] = Object.values(
    pendingConfigurationChangesList
  ).map((el: PendingChangeType) => {
    const {
      configurationName,
      dataType,
      configurationItemId,
      configurationId,
      configurationTypeId,
      validationRules,
      currentConfigurationItemValues,
      uniquePerEnvironment,
      description,
      integrationName
    } = el;

    const configurationItem: ConfigurationItemType = {
      name: configurationName,
      integrationName,
      dataType,
      description,
      configurationId,
      configurationTypeId,
      configurationItemId,
      uniquePerEnvironment,
      validationRules,
      configurationValues: currentConfigurationItemValues
    };
    return configurationItem;
  });
  const siteToDuplicateOrCreateData: SubmitDuplicateOrCreateSiteBody = {
    site: generalSiteInformation,
    fromSiteId,
    configurationItems,
    environmentIds: selectedEnvironmentsList
  };

  const [triggerPostDuplicateOrCreateSite, result] =
    useLazyPostDuplicateOrCreateSiteQuery();
  const [triggerFetchSiteListByClientId] = useLazyGetSitesListByClientQuery();

  const submitSiteToDuplicateOrCreateChanges = () => {
    if (isInNewSiteCreatingMode) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const siteToDuplicateOrCreateFormatted = convertObjectToSnakeCase(
        siteToDuplicateOrCreateData
      );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      triggerPostDuplicateOrCreateSite(siteToDuplicateOrCreateFormatted);
    }
  };

  useEffect(() => {
    if (result.isSuccess && !!result.data && clientId) {
      triggerFetchSiteListByClientId({ clientId });
    }
  }, [result.isSuccess, result.data, triggerFetchSiteListByClientId]);
  return {
    ...result,
    submitSiteToDuplicateOrCreateChanges
  };
};

export default usePostDuplicateOrCreateSiteHook;
