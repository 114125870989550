import { ChangeEvent } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

export type OptionType = {
  label: string;
  id: string;
  additionalData?: unknown;
};

type SearchBarPropsType = {
  inputLabel?: string;
  inputPlaceholder?: string;
  inputValue: string;
  isEnterBtnDisabled?: boolean;
  options: OptionType[];
  onSelect: (site: OptionType | null) => void;
  onInputValueChange: (inputValue: string) => void;
  onEnterBtnClick?: () => void;
  autocompleteMatcher?: (option: OptionType, value: OptionType) => boolean;
};

const SearchBar = (props: SearchBarPropsType) => {
  const {
    options,
    inputLabel = "",
    inputPlaceholder = "",
    onSelect,
    onInputValueChange,
    inputValue,
    isEnterBtnDisabled = false,
    onEnterBtnClick,
    autocompleteMatcher
  } = props;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "5px"
      }}
    >
      <Autocomplete
        data-testid="searchbar-autocomplete"
        sx={{
          minWidth: 300
        }}
        isOptionEqualToValue={autocompleteMatcher}
        inputValue={inputValue}
        options={options}
        autoComplete
        getOptionLabel={(option: OptionType) => option.label}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            {option.label}
          </Box>
        )}
        onChange={(_e: ChangeEvent<unknown>, option: OptionType | null) => {
          onSelect(option);
        }}
        onInputChange={(_e: ChangeEvent<unknown>, inputValue: string) => {
          onInputValueChange(inputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={inputLabel}
            placeholder={inputPlaceholder}
          />
        )}
      />
      {onEnterBtnClick && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <IconButton
            aria-label="arrow-circle-btn"
            data-testid="enter-btn"
            disableRipple
            disabled={isEnterBtnDisabled}
            onClick={onEnterBtnClick}
          >
            <ArrowCircleRightOutlinedIcon
              sx={{
                height: 45,
                width: 45
              }}
            />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default SearchBar;
